import React from "react";
import {
  Heading,
  Grid,
  Stack,
  Text,
  Box,
  createToast,
} from "@kiwicom/orbit-components";

import { PlusCircle } from "@kiwicom/orbit-components/lib/icons";

import AddVariantWrapper from "./styles";
import VariantItem from "./VariantItem";
import { useStoreState, useStoreActions } from "easy-peasy";

const AddVariants = ({ title, onDone }) => {
  const variants = useStoreState((state) => state.variants);

  const addVariantAction = useStoreActions((actions) => actions.addVariant);

  const getId = (min = 1, max = 100) => Math.random() * (max - min) + min;

  const addItem = () => {
    addVariantAction({
      id: `${getId()}`,
      name: "",
      // mrp: 0,
      // margin: 0,
      // price: 0,
      // stock: 0,
    });
  };

  const showValidationError = () =>
    createToast("Please fill all required fields properly");

  const confirmAddingAllVariants = () => {
    if (variants.length <= 0) {
      showValidationError()
    } else {
      onDone();
    }
  };

  return (
    <>
      <Stack
        spaceAfter="largest"
        direction="row"
        justify="between"
        align="center"
      >
        <Heading>{title}</Heading>
        {/* <ButtonGroup spacing="medium">
          {variants.length > 0 && (
            <Button iconLeft={<Plus />} onClick={() => addItem()}>
              Add Another Variant
            </Button>
          )}
          {variants.length > 0 && (
            <Button
              iconLeft={<Check />}
              onClick={() => confirmAddingAllVariants()}
            >
              Done
            </Button>
          )}
        </ButtonGroup> */}
      </Stack>

      {variants.length === 0 ? (
        <AddVariantWrapper onClick={() => addItem()}>
          <PlusCircle />

          <Box padding={{ top: "medium" }}>
            <Text align="center">
              Click here to add a variant of your product
            </Text>

            <Text type="secondary">
              You must need to add at least one to continue
            </Text>
          </Box>
        </AddVariantWrapper>
      ) : (
        <Grid columns="repeat(auto-fit, minmax(300px, 1fr));" columnGap="3rem">
          {variants.map((variant, index) => (
            <VariantItem variant={variant} isFinalVariant={index === (variants.length - 1)} addAnotherVariant={() => addItem()}
              onDone={() => confirmAddingAllVariants()} key={variant.id} />
          ))}
        </Grid>
      )}
    </>
  );
};

export default AddVariants;
