import {
  query,
  startAfter,
  collection,
  getDocs,
  getDoc,
  doc,
  writeBatch,
  orderBy,
  limit,
  setDoc,
  updateDoc,
} from "firebase/firestore";


import {
  getPrice,
  getProductVariants,
  generateId,
  getSearchList,
} from "../utils/helpers";
import { db } from "../database";
import { uploadImages } from "./upload.service";
import { kShopFirestoreKey as root } from "../constants";

const getProductRef = (productId) => doc(db, root, productId);

const getVariantRef = (productId, variantId) =>
  doc(db, root, productId, "items", variantId);


export const updateVariant = async (variant) => {

  const { product_id: productId, id: variantId } = variant;

  try {
    const productRef = getProductRef(productId);

    await updateDoc(productRef, { mrp: variant.mrp, price: variant.price });
    const docRef = getVariantRef(productId, variantId);
    variant.discount = parseInt(variant.discount);
    await setDoc(docRef, variant, {
      merge: true,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const deleteProduct = async (productId) => {
  try {
    // start batch 
    const batch = writeBatch(db);

    // product ref 
    const docRef = getProductRef(productId);

    // fetch all sub collections
    const querySnapshot = await getDocs(collection(db, root, productId, "items"));
    querySnapshot.forEach((doc) => {
      // delete sub collection
      batch.delete(doc.ref);
    });
    // delete the main document
    batch.delete(docRef);

    await batch.commit();
    // await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const getVariants = async (productId) => {
  let variants = [];

  try {
    const querySnapshot = await getDocs(
      collection(db, root, productId, "items")
    );

    querySnapshot.docs.forEach((doc) => variants.push(doc.data()));

    return variants;
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const getVariantsByID = async (productId,variantId) => {
  // let variants = [];
console.log(productId,variantId);
  try {
    const querySnapshot = await getDoc(
      getVariantRef(productId,variantId)
    );

    console.log(querySnapshot.data());
    // querySnapshot.docs.forEach((doc) => console.log(doc.data()));

    return querySnapshot.data();
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const getProducts = async (lastVisible) => {
  if (lastVisible === undefined)
    return Promise.reject("there are no orders left");

  const productQuery = query(
    collection(db, root),
    orderBy("name"),
    startAfter(lastVisible),
    limit(9)
  );

  try {
    const querySnapshot = await getDocs(productQuery);
    console.log(querySnapshot.docs);

    return querySnapshot.docs;
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const addProduct = async ({
  name,
  label,
  brand,
  category,
  description,
  variants,
  imageFiles,
}) => {
  const productId = generateId(name);

  const productRef = getProductRef(productId);

  const images = await uploadImages(imageFiles);

  const productVariants = getProductVariants(variants);

  const { price, mrp } = getPrice(variants);

  const weightUnits = variants[0].weightUnit;

  const batch = writeBatch(db);
  
  let variantMap = [];

  

  variants.forEach((variant) => {
    const variantId = `${productId}_${variant.weight}_${variant.weightUnit}`;
    
    // console.log(index);
    variantMap.push({
      id:variantId,
      weight_units:variant.weightUnit,
      weight:variant.weight
    });
console.log(variantMap);

    const variantRef = getVariantRef(productId, variantId);
    variant.id = variantId;

    batch.set(variantRef, {
      id: variantId,
      product_id: productId,
      location: "Tirupati",
      ...variant,
    });
  });

  batch.set(productRef, {
    id: productId,
    name,
    label,
    brand,
    category,
    description,
    price,
    mrp,
    status: "available",
    locations: ["Tirupati"],
    weightUnit: weightUnits,
    variants: productVariants,
    meta_data: {
      searchList: getSearchList(name),
    },
    variants_details: variantMap,
    created_at: Date.now(),
    images,
  });

  try {
    await batch.commit();
    console.log(`Document written at shop-products/${productId}`);

    return true;
  } catch (e) {
    console.error("Error adding document: ", e);
    return Promise.reject("Something went wrong", e);
  }
};
