/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@kiwicom/orbit-components";
import { useParams } from "react-router-dom";
import { getVariants } from "../../services/product.service";
import VariantItem from "./VariantItem";

const UpdateProductStock = () => {
  const [variants, setVariants] = useState([]);

  const { productId } = useParams();
  useEffect(() => {
    getVariants(productId).then((v) => setVariants(v));
  }, []);

  return (
    <Stack>
      <Grid columns="repeat(auto-fit, minmax(240px, 1fr));" columnGap="2rem">
        {variants.map((variant) => (
          <VariantItem
            onAdd={() => {
              console.log("added");
            }}
            onRemove={() => {
              console.log("removed");
            }}
            variant={variant}
            key={variant.id}
          />
        ))}
      </Grid>
    </Stack>
  );
};

export default UpdateProductStock;
