/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Stack,
  Grid,
  Loading,
  Button,
  Box,
  Heading,
  createToast,
} from "@kiwicom/orbit-components";
import { Reload } from "@kiwicom/orbit-components/icons";
import { getProducts, deleteProduct } from "../../services/product.service";
import ProductItem from "./ProductItem";
import { useNavigate } from "react-router-dom";

const ViewProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);

  const populateDocs = (docs) => {
    if (docs === undefined) return setLoading(false);
    setLastDoc(docs[docs.length - 1]);

    let data = [];

    docs.forEach((doc) => {
      console.log(doc.data());
      data.push({ ...doc.data() });
    });

    setProducts((prevData) => [...prevData, ...data]);
    setLoading(false);
  };

  const fetchProducts = () => {
    setLoading(true);
    getProducts(lastDoc).then(
      (docs) => {
        populateDocs(docs);
      },
      (_) => {
        setLoading(false);
      }
    );
  };
  const navigate = useNavigate();

  const deleteProductFromStore = async (productId) => {
    await deleteProduct(productId).then(() => {
      navigate("/");

      createToast("Product deleted successfully");
    });
    // fetch updated products list from firebase
  };

  useEffect(() => fetchProducts(), []);

  return !loading ? (
    <Stack direction="column" align="center">
      {/* <InputField
        placeholder="Search"
        spaceAfter="largest"
        suffix={
          <ButtonLink>
            <Search />
          </ButtonLink>
        }
      /> */}

      <Grid
        largeDesktop={{ columns: "repeat(3, 1fr)" }}
        mediumMobile={{ columns: "repeat(2, 1fr)" }}
        rowGap="1.5rem"
        columnGap="1rem"
      >
        {products.map((product) => {
          return (
            <ProductItem
              key={product.id}
              product={product}
              deleteProductFun={() => deleteProductFromStore(product.id)}
            />
          );
        })}
      </Grid>
      {products.length > 0 && (
        <Box padding={{ bottom: "XXLarge" }}>
          <Button
            iconLeft={<Reload />}
            type="secondary"
            onClick={() => getProducts()}
            spaceAfter="largest"
          >
            Load More
          </Button>
        </Box>
      )}
      {products.length === 0 && (
        <Box padding={{ bottom: "XXLarge" }}>
          <Heading>No Products Found.</Heading>
        </Box>
      )}
    </Stack>
  ) : (
    <Loading type="pageLoader" />
  );
};

export default ViewProducts;
