import React from "react";
import { useState } from "react";
import {
  Loading,
  Table,
  TableBody,
  ButtonLink,
  Button,
  Stack,
  Box,
  InputField,
  Illustration,
} from "@kiwicom/orbit-components";

import OrderHeader from "../../components/OrderHeader";
import OrderFooter from "../../components/OrderFooter";
import { fetchCustomerOrders } from "../../services/order.service";
import OrderItem from "./OrderItem";
import { items } from "./data";
import { Reload, Search } from "@kiwicom/orbit-components/lib/icons";

const CustomerOrders = () => {
  const [loading, setLoading] = useState(false);
  const [orders, fetchShopOrders] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [customerId, setCustomerId] = useState("");

  const populateDocs = (docs) => {
    if (docs === undefined) return setLoading(false);
    setLastDoc(docs[docs.length - 1]);

    let data = [];

    docs.forEach((doc) => {
      data.push({ ...doc.data(), ref: doc.ref });
    });

    fetchShopOrders((prevData) => [...prevData, ...data]);
    setLoading(false);
  };

  const getOrders = () => {
    setLoading(true);
    fetchCustomerOrders(lastDoc, customerId).then(
      (result) => {
        populateDocs(result);
      },
      (_) => setLoading(false)
    );
  };

  const removeItem = (value) => {
    fetchShopOrders((prevState) => prevState.filter((item) => item !== value));
  };

  return !loading ? (
    <Box elevation="action" borderRadius="large" padding="large">
      <Stack direction="column" justify="between" align="start">
        <InputField
          placeholder="Enter customer number"
          spaceAfter="largest"
          onChange={(event) => setCustomerId(event.currentTarget.value)}
          suffix={
            <ButtonLink onClick={(event) => getOrders()}>
              <Search />
            </ButtonLink>
          }
        />
        {orders.length > 0 ? (
          <Table striped={false}>
            <OrderHeader items={items} />
            <TableBody>
              {orders.map((order, i) => (
                <OrderItem
                  onDone={() => removeItem(order)}
                  key={i}
                  order={order}
                />
              ))}
            </TableBody>
            <OrderFooter
              length={6}
              suffix={
                <Button
                  size="small"
                  iconLeft={<Reload />}
                  type="secondary"
                  onClick={() => getOrders()}
                >
                  Load more
                </Button>
              }
            />
          </Table>
        ) : (
          <Stack direction="column" justify="center" align="center">
            <Illustration spaceAfter="large" name="OpenSearch" />
          </Stack>
        )}
      </Stack>
    </Box>
  ) : (
    <Loading type="pageLoader" />
  );
};

export default CustomerOrders;
