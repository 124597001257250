import React, { useState } from "react";
import {
  InputField,
  Stack,
  Button,
  ButtonLink,
  Text,
  createToast,
} from "@kiwicom/orbit-components";
import { updateVariant } from "../../services/product.service";
import { DealsV2, Check,  Messages} from "@kiwicom/orbit-components/icons";
import { useForm } from "react-hook-form";
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const VariantItem = ({
  variant: {
    id,
    weight: initName,
    mrp: initMrp,
    margin: initMargin,
    price: initPrice,
    stock: initStock,
    product_id: productId,
    weightUnit: initWeightUnit,
    discount: initDiscount,
  },
}) => {
  const navigate = useNavigate();

  // let vetremediCost = isNaN(initMrp - initMrp * (initMargin / 100)) ?0:(initMrp - initMrp * (initMargin / 100));

  // let productPriceForCustomer =isNaN(initMrp - initMrp * (initDiscount / 100))?0:initMrp - initMrp * (initDiscount / 100);
  let vetremediCost = initMrp - (initMrp * (initMargin / 100));
    let profitForVetremedi = initMrp - vetremediCost;
    let customerDiscount = (profitForVetremedi * (initDiscount / 100));

  const [name, setName] = useState(initName ?? "s");
  const [mrp, setMrp] = useState(initMrp ?? 0);
  const [margin, setMargin] = useState(initMargin ?? 0);
  const [price, setPrice] = useState(initPrice ?? 0);
  const [stock, setStock] = useState(initStock ?? 0);
  const [discount, setDiscount] = useState(initDiscount ?? 0);
  const [profitOnEachVariant, setProfitOnEachVariant] = useState( (profitForVetremedi-customerDiscount));
  const [vetremediCostForEachVariant, setVetremediCostForEachVariant] =
    useState((vetremediCost));

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const setDiscountedCustomerPrice = ({
    productMrp,
    productDiscount,
    productMargin,
  } = {}) => {

    let vetremediCost = productMrp - (productMrp * (productMargin / 100));
    let profitForVetremedi = productMrp - vetremediCost;
    let customerDiscount = (profitForVetremedi * (productDiscount / 100));
    
    if (customerDiscount > profitForVetremedi / 2) {
       createToast("The discount cannot be more than 50% of the margin", {
        icon: <Messages />,
      });
      setDiscount(0);
      productDiscount = 0;
      return
    }
    
    // 1.calculate product cost for vetremedi 
    // 2.calculate profit for vetremedi formula = (productMrp - vetremediCost)
    // 3.calculate discount for customer formula =  vetremediProfit *(discount%/100)
    
    // let vetremediCost = productMrp - (productMrp * (productMargin / 100));
    // let profitForVetremedi = productMrp - vetremediCost;
    // let customerDiscount = (profitForVetremedi * (productDiscount / 100));
   
    setPrice(productMrp-customerDiscount);
    setVetremediCostForEachVariant(isNaN(vetremediCost) ? 0 : vetremediCost);
    setProfitOnEachVariant(
      isNaN(profitForVetremedi-customerDiscount)
        ? 0
        : profitForVetremedi-customerDiscount
    );
    // let vetremediCost = productMrp - productMrp * (productMargin / 100);
    // let productPriceForCustomer =
    //   productMrp - productMrp * (productDiscount / 100);
    // setVetremediCostForEachVariant(isNaN(vetremediCost) ? 0 : vetremediCost);
    // setProfitOnEachVariant(
    //   isNaN(productPriceForCustomer - vetremediCost)
    //     ? 0
    //     : productPriceForCustomer - vetremediCost
    // );
  };

  const setDiscountedPrice = (pcnt) => {
    let discount = pcnt / 100;
    setPrice(mrp - mrp * discount);
  };

  const getCurrentVariant = () => ({
    id,
    name,
    mrp:parseInt(mrp),
    margin:parseInt(margin),
    price:parseInt(price),
    stock:parseInt(stock),
    discount:parseInt(discount),
    product_id: productId,
  });

  const addVariant = () => {
    updateVariant(getCurrentVariant()).then((_) => {createToast("updated");
    navigate("/edit-stock/view");
  });
  };

  // const removeVariant = () => {};

  return (
    <Stack direction="column" spaceAfter="largest">
      <Stack direction="row" justify="between" align="center">
        <InputField
          key="name"
          type="text"
          disabled="true"
          label="Variant Weight"
          value={name + `${" " + initWeightUnit ?? ""}`}
          onChange={(event) => setName(event.currentTarget.value)}
        />
      </Stack>
      <InputField
        key="mrp"
        {...register("variantMRP", { required: true, minLength: 2 })}
        type="number"
        label="MRP"
        value={mrp}
        prefix="₹"
        error={errors.variantMRP && "Enter required fields"}
        onChange={(event) => {
          setMrp(event.currentTarget.value);
          setDiscountedCustomerPrice({
            productDiscount: discount,
            productMrp: event.target.value,
            productMargin: margin,
          });
        }}
      />
      <InputField
        key="margin"
        {...register("variantMargin", { required: true })}
        type="number"
        label="Margin"
        value={margin}
        prefix="%"
        error={errors.variantMargin && "Enter required fields"}
        onChange={(event) => {
          setMargin(event.currentTarget.value);
          setDiscountedCustomerPrice({
            productDiscount: discount,
            productMrp: mrp,
            productMargin: event.currentTarget.value,
          });
        }}
      />
      <InputField
        key="discount"
        type="number"
        label="Discount"
        prefix="%"
        value={discount}
        onChange={(event) => {
          setDiscount(event.currentTarget.value);
          setDiscountedCustomerPrice({
            productDiscount: event.currentTarget.value,
            productMrp: mrp,
            productMargin: margin,
          });
          // setDiscountedPrice(event.currentTarget.value);
        }}
        suffix={
          <ButtonLink
            size="small"
            onClick={(event) => setDiscountedPrice(discount)}
          >
            <DealsV2 />
          </ButtonLink>
        }
      />
      <InputField
        key="price"
        type="number"
        label="Customer Price"
        prefix="₹"
        disabled="true"
        value={price}
        onChange={(event) => setPrice(event.currentTarget.value)}
      />
      <Stack direction="column" justify="start" align="start">
        <Text align="left">
          EKHI Profit for this Product Variant ₹ {profitOnEachVariant}
        </Text>
        <Text align="left">
          EKHI Cost for this Product Variant: ₹ {vetremediCostForEachVariant}
        </Text>
      </Stack>
      <InputField
        key="stock"
        type="number"
        {...register("variantStock", { required: true })}
        error={errors.variantStock && "Enter required fields"}
        label="Stock"
        value={stock}
        onChange={(event) => setStock(event.currentTarget.value)}
      />

      <Button
        iconLeft={<Check />}
        type="primarySubtle"
        onClick={handleSubmit(addVariant)}
      >
        Update
      </Button>

      {/* <Stack direction="row" justify="between" align="center">
        <Button
          iconLeft={<Remove />}
          type="criticalSubtle"
          onClick={(event) => removeVariant()}
        >
          Remove
        </Button>
        
      </Stack> */}
    </Stack>
  );
};

export default VariantItem;
