export const generateId = (str) => {
  str = str.replace(/\s+/g, "-").toLowerCase();
  return str;
};

export const getPrice = (variants) => {
  let varPrice = 100000000;
  let varMrp = 100000000;

  variants.forEach(({ price, mrp }) => {
    varPrice = Math.min(varPrice, parseInt(price));
    varMrp = Math.min(varMrp, parseInt(mrp));
  });

  console.table(varPrice, varMrp);

  return { price: varPrice, mrp: varMrp };
};

export const getSearchList = (name) => {
  let searchList = [];
  name.split(" ").forEach((eachWOrd) => {
    for (let index = 0; index < eachWOrd.length; index++) {
      if (searchList.length > 8) {
        break;
      }
      let temp = eachWOrd.substring(0, index + 1).toLowerCase();
      if (temp.length !== 0) searchList.push(temp);
    }
  });
  return searchList;
};

export const getProductVariants = (variants) => {
  let variantNames = [];
  variants.forEach((variant) => variantNames.push(`${variant.weight}`));

  return variantNames;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
