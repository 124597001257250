import styled from "styled-components";

export const ImagePickerWrapper = styled.label`
  font-family: "Roboto", -apple-system, ".SFNSText-Regular", "San Francisco",
    "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
  position: relative;
  display: block;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  width: 100%;
`;

export const SelectedImagesWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  max-width: 1080px;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
`;
