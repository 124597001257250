import styled from "styled-components";

const Picture = styled.img`
  width: 340px;
  object-fit: contain;
  margin: 10px;
  padding: 14px;
  border-radius: 10px;
  border: 1px solid black;
`;

export default Picture;
