import {
    Loading,
  } from "@kiwicom/orbit-components";
  import './style_1.css'; // Import your stylesheet

  import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
import { DashboardData } from "./dashboard_data";
import React, { useState, useEffect } from "react";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Dashboard = () => {


  const [data, setData] = useState({});


      const getAnalytics = () => {
            DashboardData().then(
                  (result) => {
                    setData(result);
                  },
            );
      };

    const cardData = [
        { id: 1, number: '01', title: 'Card 1', icon: '🌟' },
        { id: 2, number: '02', title: 'Card 2', icon: '🎉' },
        { id: 3, number: '03', title: 'Card 3', icon: '💡' },
        { id: 4, number: '04', title: 'Card 4', icon: '🚀' },
      ];
    
    const options2 = {
        theme: "light2",
        animationEnabled: true,
        title:{
          text: "Sales and margin"
        },
        subtitles: [{
          // text: "Click Legend to Hide or Unhide Data Series"
        }],
        axisX: {
          title: "States"
        },
        axisY: {
          title: "Units Sold",
          titleFontColor: "#6D78AD",
          lineColor: "#6D78AD",
          labelFontColor: "#6D78AD",
          tickColor: "#6D78AD"
        },
        axisY2: {
          title: "Profit in Rupees",
          titleFontColor: "#51CDA0",
          lineColor: "#51CDA0",
          labelFontColor: "#51CDA0",
          tickColor: "#51CDA0"
        },
        toolTip: {
          shared: true
        },
        legend: {
          cursor: "pointer",
          itemclick:false
        },
        data: [{
          type: "spline",
          name: "Units Sold",
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          yValueFormatString: "#,##0 Units",
          dataPoints: [
            { x: new Date(2017, 0, 1), y: 120 },
            { x: new Date(2017, 1, 1), y: 135 },
            { x: new Date(2017, 2, 1), y: 144 },
            { x: new Date(2017, 3, 1), y: 103 },
            { x: new Date(2017, 4, 1), y: 93 },
            { x: new Date(2017, 5, 1), y: 129 },
            { x: new Date(2017, 6, 1), y: 143 },
            { x: new Date(2017, 7, 1), y: 156 },
            { x: new Date(2017, 8, 1), y: 122 },
            { x: new Date(2017, 9, 1), y: 106 },
            { x: new Date(2017, 10, 1), y: 137 },
            { x: new Date(2017, 11, 1), y: 142 }
          ]
        },
        {
          type: "spline",
          name: "Profit",
          axisYType: "secondary",
          showInLegend: true,
          xValueFormatString: "MMM YYYY",
          yValueFormatString: "$#,##0.#",
          dataPoints: [
            { x: new Date(2017, 0, 1), y: 19034.5 },
            { x: new Date(2017, 1, 1), y: 20015 },
            { x: new Date(2017, 2, 1), y: 27342 },
            { x: new Date(2017, 3, 1), y: 20088 },
            { x: new Date(2017, 4, 1), y: 20234 },
            { x: new Date(2017, 5, 1), y: 29034 },
            { x: new Date(2017, 6, 1), y: 30487 },
            { x: new Date(2017, 7, 1), y: 32523 },
            { x: new Date(2017, 8, 1), y: 20234 },
            { x: new Date(2017, 9, 1), y: 27234 },
            { x: new Date(2017, 10, 1), y: 33548 },
            { x: new Date(2017, 11, 1), y: 32534 }
          ]
        }]
      }

    const options3 = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "dark2", // "light1", "dark1", "dark2"
        title:{
          text: "Margin Split for medicine sales"
        },
        data: [{
          type: "pie",
          indexLabel: "{label}: {y} ₹",		
          startAngle: -90,
          dataPoints: [
            { y: data.doctor, label: "Doctors" },
            { y: data.vetremedi, label: "vetremedi.com" },
            { y: data.pharmacy, label: "Pharmacy" },
            { y: data.delivery, label: "delivery" },
          ]
        }]
      }

    const options4 = {
        animationEnabled: true,
        exportEnabled: true,
        theme: "dark2", // "light1", "dark1", "dark2"
        title:{
          text: "Margin Split on shop sales"
        },
        data: [{
          type: "pie",
          indexLabel: "{label}: {y} ₹",		
          startAngle: -90,
          dataPoints: [
            { y: data.shop_vetremedi, label: "vetremedi.com" },
            { y: data.shop_delivery, label: "Delivery" },    

          ]
        }]
      }

    const options = {
        title: {
          text: "User Data"
        },
        data: [{
          type: "column",
          dataPoints: [
            { label: "Customers",  y: 35 },
            { label: "Doctors", y: 15  },
            { label: "Pharmacies", y: 25  },
            { label: "Delivery",  y: 3 },
           
          ]
        }]
      }

      useEffect(() => getAnalytics(), []);
      return (
        <div>
        {/* <CanvasJSChart options = {options}
           onRef = {ref => this.chart = ref}
        /> */}

(
      {/* <div>
        <CanvasJSChart options = {options2} 
          //  onRef={ref => this.chart = ref}
        />
        {}
      </div> */}
      <div>
      <CanvasJSChart options = {options3} 
     
      
				/* onRef={ref => this.chart = ref} */
			/>
      {/* <p>Total orders placed {data.totalOrdersTillDate}</p> */}
          <CanvasJSChart options = {options4} 
      
      /* onRef={ref => this.chart = ref} */
    /> 

      </div>
      );
      </div>
        
      );
}


export default Dashboard