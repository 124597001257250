import React, { useState, useEffect } from "react";
import { Box, Stack } from "@kiwicom/orbit-components";
import UserTile from "./UserTile";
import SidebarTile from "./SidebarTile";
import { getNavData } from "./data";

const Sidebar = () => {
  const [sidebarData, setSidebarData] = useState([]);

  useEffect(() => {
    let data = getNavData();
    setSidebarData(data);
  }, []);

  return (
    <Stack>
      <UserTile title="Mohan" subtitle="admin" />
      <Box padding={{top: "XLarge"}} >
      {sidebarData.map((tile) => (
        <SidebarTile key={tile.label} tile={tile} />
      ))}
      </Box>
    </Stack>
  );
};

export default Sidebar;
