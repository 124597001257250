/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Table,
  TableBody,
  Checkbox,
  Heading,
  Button,
  Stack,
  Box,
  Dialog,
  Inline,
  Loading,
  Illustration,
  Text
} from "@kiwicom/orbit-components";

import {
  AirplaneTakeoff,
  Close,
  Reload,
} from "@kiwicom/orbit-components/icons";

import OrderItem from "./OrderItem";
import OrderHeader from "../../components/OrderHeader";
import OrderFooter from "../../components/OrderFooter";
import { updateOrderStatus, fetchOrders } from "../../services/order.service";
import { items, getSelectedOrders } from "./data";

const PaidOrdersView = () => {
  const [loading, setLoading] = useState(true);
  const [orders, fetchShopOrders] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [checked, setChecked] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  const initCheckedArray = (orders) => {
    let checkArray = {};
    orders.forEach((doc) => (checkArray[doc.id] = false));
    setChecked(checkArray);
  };

  const updateStatus = () => {
    setShowDialog(false);
    const selectedOrders = getSelectedOrders(checked);

    console.log(selectedOrders);

    updateOrderStatus(selectedOrders, "shipped").then(
      (value) => {
        console.log(value);
        if (value)
          selectedOrders.forEach((orderId) => {
            fetchShopOrders((prevState) =>
              prevState.filter(({ id }) => id !== orderId)
            );
          });

        alert("Updated Successfully");
      },
      (reason) => {
        alert(reason);
      }
    );
  };

  const populateDocs = (docs, refresh) => {
    if (docs === undefined) return setLoading(false);
    setLastDoc(docs[docs.length - 1]);

    let data = [];

    docs.forEach((doc) => {
      data.push({ ...doc.data(), ref: doc.ref });
    });

    if (!refresh) fetchShopOrders((prevData) => [...prevData, ...data]);
    else fetchShopOrders(data);

    initCheckedArray(orders);
    setLoading(false);
  };

  const getOrders = (refresh = false) => {
    setLoading(true);

    fetchOrders(refresh ? null : lastDoc, "paid").then(
      (result) => {
        populateDocs(result, refresh);
      },
      (_) => setLoading(false)
    );
  };

  useEffect(() => getOrders(), []);

  return !loading ? (
    <Box elevation="action" borderRadius="large" padding="large">
      {showDialog && (
        <Dialog
          title="Ship current orders"
          description="Do you want to ship the marked orders?"
          renderInPortal={false}
          primaryAction={<Button onClick={() => updateStatus()}>Yes</Button>}
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowDialog(false);
              }}
            >
              No
            </Button>
          }
        />
      )}
      <Stack
        direction="row"
        justify="between"
        align="center"
        spaceAfter="largest"
      >
        <Heading type="title2" spaceAfter="largest">
          Orders to be Shipped
        </Heading>

        <Inline spacing="medium">
          <Button
            iconLeft={<Reload />}
            size="small"
            type="white"
            onClick={() => getOrders(true)}
          >
            Refresh
          </Button>

          {!showDialog && (
            <Button
              iconLeft={<AirplaneTakeoff />}
              size="small"
              onClick={() => setShowDialog(true)}
            >
              Ship Selected Orders
            </Button>
          )}

          <Button
            size="small"
            type="primarySubtle"
            iconLeft={<Close />}
            onClick={() => initCheckedArray(orders)}
          >
            Clear all
          </Button>
        </Inline>
      </Stack>

      {orders.length > 0 ? (
        <Table striped={false}>
          <OrderHeader items={items} />
          <TableBody>
            {orders.map((order, i) => (
              <OrderItem key={i} order={order}>
                <Checkbox
                  checked={checked[order.id]}
                  onChange={() => {
                    let obj = { ...checked };
                    obj[order.id] = !checked[order.id];
                    setChecked(obj);
                  }}
                />
              </OrderItem>
            ))}
          </TableBody>
          <OrderFooter
            length={7}
            suffix={
              <Button
                size="small"
                iconLeft={<Reload />}
                type="secondary"
                onClick={() => getOrders()}
              >
                Load more
              </Button>
            }
          />
        </Table>
      ) : (
        <Stack direction="column" justify="center" align="center">
          <Illustration spaceAfter="large" name="NoResults" />
          <Text size="large">No orders right now</Text>
        </Stack>
      )}
    </Box>
  ) : (
    <Loading type="pageLoader" />
  );
};

export default PaidOrdersView;
