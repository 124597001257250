import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@kiwicom/orbit-components";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0.5rem;
  border: 0.1rem solid rgb(235, 244, 236);
  border-radius: 3px;
  color: rgba(0, 127, 109, 1);

  a {
    text-decoration: none;
    color: rgba(0, 127, 109, 1);
  }

  &:hover {
    background-color: rgb(235, 244, 236);
  }
`;

const LinkTile = ({ to, title }) => {
  return (
    <Wrapper>
      <Link to={to}>
        <Text weight="bold" size="normal">
          {title}
        </Text>
      </Link>
    </Wrapper>
  );
};

export default LinkTile;
