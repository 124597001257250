import { Heading, Stack, Text } from "@kiwicom/orbit-components";
import { Logout } from "@kiwicom/orbit-components/lib/icons";
import React from "react";
import styled from "styled-components";
import { getAuth, signOut } from 'firebase/auth';
import CircleAvatar from '../CircleAvatar'

const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
`;

const UserInfoWrapper = styled.div`
  flex: 1;
`

const UserTile = ({ image, title, subtitle }) => {
  const logOut = () => {
    signOut(getAuth());
  };

  return (
    <Stack direction="row" align="center" justify="around" >
      <CircleAvatar
        src="https://www.w3schools.com/howto/img_avatar.png"
        alt="user-profile-image"
      />
      <UserInfoWrapper>
        <Heading type="title4">{title ?? "UserName"}</Heading>
        <Text type="secondary">{subtitle ?? "UserType"}</Text>
      </UserInfoWrapper>
      <IconButton onClick={(event) => logOut()}>
        <Logout size="medium" />
      </IconButton>
    </Stack>
  );
};

export default UserTile;
