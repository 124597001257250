import React from "react";

import {
  TableRow,
  TextLink,
  List,
  Text,
  ListItem,
  TableCell,
} from "@kiwicom/orbit-components";

const OrderItem = ({ order, children }) => {
  const {
    timestamp,
    items,
    address: { contact, state, zipcode },
    order: { item_total, razorpay_order },
    user: { name, phone },
  } = order;
  return (
    <TableRow>
      <TableCell key="check">{children}</TableCell>
      <TableCell key="user_info">
        <Text key="name">{name}</Text>
        <Text key="phone">{phone}</Text>
      </TableCell>
      <TableCell key="timestamp">{timestamp}</TableCell>
      <TableCell>
        <List>
          {items.map((item, i) => (
            <ListItem key={i}>
              {item.name} x{item.quantity}
            </ListItem>
          ))}
        </List>
      </TableCell>
      <TableCell key="address">
        <Text key="contact">{contact}</Text>
        <Text key="state">{state}</Text>
        <Text key="zipcode">{zipcode}</Text>
      </TableCell>
      <TableCell key="bill">₹{item_total}</TableCell>
      <TableCell key="payment">
        <TextLink
          external={true}
          href={`https://dashboard.razorpay.com/app/orders/${razorpay_order}`}
        >
          View Payment
        </TextLink>
      </TableCell>
    </TableRow>
  );
};

export default OrderItem;
