import React, { useState } from "react";
import { Check, Close, Document } from "@kiwicom/orbit-components/icons";
import { capitalizeFirstLetter } from "../utils/helpers";
import CircleAvatar from "./CircleAvatar";
import { Link } from "react-router-dom";

import {
  Stack,
  Card,
  Text,
  Button,
  Dialog,
  ButtonLink,
  InputField,
  CardSection,
  ButtonGroup,
  InputStepper,
  Heading,
  Select,
  createToast,
  NotificationBadge,
} from "@kiwicom/orbit-components";
import { approveDoctor, rejectDoctor } from "../services/doctor.service";

const DoctorInfoTile = ({
  name,
  profile,
  designation,
  spec,
  verified,
  approve,
  decline,

}) => (
  <Stack direction="column">
    <Stack direction="row" justify="between" align="center">
      <CircleAvatar
        src={profile ?? "http://placeimg.com/640/480/food"}
        alt="user-profile-image"
      />

      {verified === "review" && <ButtonGroup>
        <ButtonLink size="small" onClick={() => approve()}>
          <Check />
        </ButtonLink>
        <ButtonLink size="small" type="critical" onClick={() => decline()}>
          <Close />
        </ButtonLink>
      </ButtonGroup>}
    </Stack>
    <Stack spacing="XXXSmall">
      <Text size="large" weight="bold">
        {name}
      </Text>
      <Text type="info">{designation}</Text>
      <Text>Species : </Text>
      <Text>
        {spec.map((element) => (
          <span>{`${capitalizeFirstLetter(element)} `}</span>
        ))}
      </Text>
    </Stack>
  </Stack>
);

const DoctorItem = ({ doctor, removeDoctorFromList }) => {
  // destructuring
  const {
    id,
    name,
    image,
    verified,
    profile: { bvsc, mvsc, license, designation, species },
  } = doctor;
  

  const [showRejectDialog, setShowRejectDialog] = React.useState(false);
  const [showApproveDialog, setShowApproveDialog] = React.useState(false);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(3);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [fee, setFee] = useState(10);
  const levels = [
    {
      label: "Beginner",
      value: "Beginner",
    },
    {
      label: "Intermediate",
      value: "Intermediate",
    },
    {
      label: "Expert",
      value: "Expert",
    },
  ];

  const openFileHandle = (url) => {
    window.open(url);
  };

  const taskSuccess = () => alert("Doctor Reviewed successfully");
  const taskError = () => alert("Something went wrong");

  const approve = () => {
    // validate fee value
    // eslint-disable-next-line no-extend-native
    String.prototype.isNumber = function () {
      return /^\d+$/.test(this);
    };
    console.log(`current fee value : ${fee}`);
    if (fee.toString().length === 0) {
      createToast("Please enter the doctor appointment fee", {
        icon: <NotificationBadge />,
      });
      return;
    }
    let digits_only = (string) =>
      [...string].every((c) => "0123456789".includes(c));
    if (!digits_only(fee.toString())) {
      createToast("Fee should be number value", {
        icon: <NotificationBadge />,
      });
      return;
    }
    if (selectedLevel.length === 0) {
      createToast("Please select doctor experience level", {
        icon: <NotificationBadge />,
      });
      return;
    }
    approveDoctor(id, rating, fee, selectedLevel)
      .then((result) => {
        if (result) {
          taskSuccess();
          removeDoctorFromList(doctor);
        }
      })
      .catch((_) => {
        taskError();
      });
  };

  const decline = () => {
    rejectDoctor(id, comment)
      .then((result) => {
        if (result) {
          taskSuccess();
          removeDoctorFromList(doctor);
        }
      })
      .catch((_) => {
        taskError();
      });
  };

  return (
    <>
      {showRejectDialog && (
        <Dialog
          title={<Heading>Reject Doctor</Heading>}
          description={
            <InputField
              key="comment"
              value={comment}
              placeholder="Add Comments"
              onChange={(event) => {
                setComment(event.currentTarget.value);
              }}
            />
          }
          renderInPortal={false}
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowRejectDialog(false);
              }}
            >
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              type="primary"
              onClick={() => {
                decline();
              }}
            >
              Submit Review
            </Button>
          }
        />
      )}
      {showApproveDialog && (
        <Dialog
          renderInPortal={false}
          title={<Heading>Approve Doctor</Heading>}
          description={
            <Stack>
              <InputStepper
                key="rating"
                value={rating}
                label="Rating"
                defaultValue={rating}
                minValue={3}
                maxValue={5}
                onChange={(val) => {
                  setRating(val);
                }}
              />{" "}
              <InputField
                key="fee"
                value={fee}
                type="number"
                label="Appointment Fee"
                placeholder="Appointment Fee"
                onChange={(evt) => {
                  setFee(evt.currentTarget.value);
                }}
              />
              <Select
                key="level"
                value={selectedLevel}
                placeholder="Select Level"
                options={levels}
                label="Experience Level"
                onChange={(event) =>
                  setSelectedLevel(event.currentTarget.value)
                }
              />
            </Stack>
          }
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowApproveDialog(false);
              }}
            >
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              type="primary"
              onClick={() => {
                approve();
              }}
            >
              Submit Review
            </Button>
          }
        />
      )}
      {verified =="approved" &&

<Link to={'/profiles/doctor/' +id}>
<Card
 header={
   <DoctorInfoTile
     name={name}
     profile={image}
     designation={designation}
     spec={species}
     verified={verified}
     approve={() => setShowApproveDialog(true)}
     decline={() => setShowRejectDialog(true)}
   />
 }
>
 <CardSection
   expandable
   title="View Certificates"
   icon={<Document ariaLabel="female" />}
 >
   <ButtonLink size="small" onClick={(_) => openFileHandle(bvsc.url)}>
     BVSc Certificate
   </ButtonLink>
   {mvsc.url && (
     <ButtonLink size="small" onClick={(_) => openFileHandle(mvsc.url)}>
       MVSc Certificate
     </ButtonLink>
   )}
   <ButtonLink size="small" onClick={(_) => openFileHandle(license.url)}>
     VCI License
   </ButtonLink>
 </CardSection>
</Card>
</Link>
        
      }

{verified !="approved" &&

<Card
 header={
   <DoctorInfoTile
     name={name}
     profile={image}
     designation={designation}
     spec={species}
     verified={verified}
     approve={() => setShowApproveDialog(true)}
     decline={() => setShowRejectDialog(true)}
   />
 }
>
 <CardSection
   expandable
   title="View Certificates"
   icon={<Document ariaLabel="female" />}
 >
   <ButtonLink size="small" onClick={(_) => openFileHandle(bvsc.url)}>
     BVSc Certificate
   </ButtonLink>
   {mvsc.url && (
     <ButtonLink size="small" onClick={(_) => openFileHandle(mvsc.url)}>
       MVSc Certificate
     </ButtonLink>
   )}
   <ButtonLink size="small" onClick={(_) => openFileHandle(license.url)}>
     VCI License
   </ButtonLink>
 </CardSection>
</Card>

        
      }
     
    </>
  );
};

export default DoctorItem;
