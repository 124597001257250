import React, { useState } from "react";
import Sidebar from "../Sidebar";
import {
  Drawer,
  Hide,
  Button,
  Stack,
  ToastRoot,
} from "@kiwicom/orbit-components";
import { MenuHamburger, Chart } from "@kiwicom/orbit-components/icons";
import StyledWrapper from "./styles//StyledWrapper";
import StyledMain from "./styles/StyledMain";
import StyledMiddle from "./styles/StyledMiddle";
import StyledSidebarWrapper from "./styles/StyledSidebarWrapper";
import { useAuthState } from "../../services/auth.service";
const Layout = ({ children }) => {
  const { isAuthenticated } = useAuthState();
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <StyledWrapper>
      <StyledMiddle>
        {isAuthenticated && (
          <Hide on={["smallMobile", "mediumMobile", "largeMobile", "tablet"]}>
            <StyledSidebarWrapper>
              <Sidebar />
            </StyledSidebarWrapper>
          </Hide>
        )}
        <StyledMain>
          <ToastRoot placement="bottom-right" />
          {isAuthenticated && (
            <Hide on={["desktop", "largeDesktop"]}>
              <Stack justify="between" align="center" spaceAfter="largest">
                {showDrawer && (
                  <Drawer
                    onClose={() => {
                      setShowDrawer(false);
                    }}
                    shown={showDrawer}
                  >
                    <Sidebar />
                  </Drawer>
                )}
                <Chart />
                <Button
                  iconLeft={<MenuHamburger />}
                  title="Open menu"
                  onClick={() => {
                    setShowDrawer(true);
                  }}
                />
              </Stack>
            </Hide>
          )}
          {children}
        </StyledMain>
      </StyledMiddle>
    </StyledWrapper>
  );
};

export default Layout;
