import * as geofire from "geofire-common";
import {
  doc,
  collection,
  getDocs,
  orderBy,
  query,
  startAt,
  endAt,
  getDoc,
} from "firebase/firestore";
import { db } from "../database";
import { kDriversFirestoreKey } from "../constants/keys";

// JS doesn't support named params by default,
export const getNearByPharmacies = async ({
  driverId,
  searchRadius = 30,
} = {}) => {
  console.log("fetching nearby pharmacies to the driver");
  console.log(driverId);
  const driverRef = doc(db, kDriversFirestoreKey, driverId);
  const docSnap = await getDoc(driverRef);
  let driverLat, driverLon;

  if (docSnap.exists()) {
    driverLat =
      docSnap.data()["address"]["position"]["geopoint"]["latitude"] ?? 0.0;
    driverLon =
      docSnap.data()["address"]["position"]["geopoint"]["longitude"] ?? 0.0;
    console.log("Driver Document data:", docSnap.data());
    console.log(driverLat, driverLon);
  } else {
    console.log("No such driver found document!");
    return;
  }

  const center = [driverLat, driverLon];
  const radiusInM = searchRadius * 1000;
  const bounds = geofire.geohashQueryBounds(center, radiusInM);
  const promises = [];
  for (const b of bounds) {
    const geoQuery = query(
      collection(db, "pharmacies"),
      orderBy("address.position.geohash"),
      startAt(b[0]),
      endAt(b[1])
    );
    promises.push(getDocs(geoQuery));
  }

  let matchingDocs = [];
  Promise.all(promises);
  for (const eachPromise of promises) {
    const snapshots = await eachPromise;
    for (const doc of snapshots.docs) {
      const pharmLat =
        doc.data()["address"]["position"]["geopoint"]["latitude"] ?? 0.0;
      const PharmLon =
        doc.data()["address"]["position"]["geopoint"]["longitude"] ?? 0.0;
      // get driver latitude and longitude
      console.log("pharmacy location details");
      console.log(pharmLat, PharmLon);

      const distanceInKm = geofire.distanceBetween(
        [pharmLat, PharmLon],
        center
      );
      console.log(
        `distance between pharmacy and driver (in km) : ${distanceInKm}`
      );
      const distanceInM = distanceInKm * 1000;
      if (distanceInM <= radiusInM) {
        matchingDocs.push(doc);
      }
    }
  }
  return matchingDocs;
};

export const distanceBetweenPharmAndDriverInKm = () => {};
