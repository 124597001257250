import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@kiwicom/orbit-components";
import { useStoreState } from "easy-peasy";

const ViewProduct = ({ product }) => {
  const variants = useStoreState((state) => state.variants);

  const { name, brand, label, category, description, imageFiles } = product;
  return (
    <Table type="secondary" striped={false}>
      <TableHead>
        <TableCell>Name</TableCell>
        <TableCell>Description</TableCell>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>name</TableCell> <TableCell>{name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>brand</TableCell> <TableCell>{brand}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>label</TableCell> <TableCell>{label}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>category</TableCell> <TableCell>{category}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>description</TableCell>
          <TableCell>{description}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>variants</TableCell>
          <TableCell>{variants.length}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Selected Images</TableCell>
          <TableCell>{imageFiles && imageFiles.length}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ViewProduct;
