import React from "react";
import {
  TableRow,
  TableCell,
  ListItem,
  List,
  TextLink,
  Text,
  Badge,
} from "@kiwicom/orbit-components";

const OrderItem = ({ order }) => {
  const {
    id: order_id,
    timestamp,
    items,
    status,
    order: { razorpay_order },
    address: { contact, state, zipcode },
  } = order;

  return (
    <TableRow>
      <TableCell key="order">{order_id}</TableCell>
      <TableCell>{timestamp}</TableCell>
      <TableCell>
        <List>
          {items.map((item, i) => (
            <ListItem key={i}>
              {item.name} x{item.quantity}
            </ListItem>
          ))}
        </List>
      </TableCell>
      <TableCell key="address">
        <Text>{contact}</Text>
        <Text>{state}</Text>
        <Text>{zipcode}</Text>
      </TableCell>
      <TableCell key="payment">
        <TextLink
          external={true}
          href={`https://dashboard.razorpay.com/app/orders/${razorpay_order}`}
        >
          View Payment
        </TextLink>
      </TableCell>
      <TableCell><Badge>{status}</Badge></TableCell>
    </TableRow>
  );
};

export default OrderItem;
