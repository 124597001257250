import { Heading, Stack } from "@kiwicom/orbit-components";
import React from "react";
import { Outlet } from "react-router";

const UpdateStockPage = () => {
  return (
    <Stack direction="column">
      <Heading spaceAfter="largest">Manage Products</Heading>
      <Outlet />
    </Stack>
  );
};

export default UpdateStockPage;
