import { Stack, Loading, Heading, Card, Grid } from "@kiwicom/orbit-components"
import styles from './profile_style.css';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { fetchAllDoctorProfile, doctorAnalytics } from "./doctors_service";
import 'react-circular-progressbar/dist/styles.css';


import { CircularProgressbar } from 'react-circular-progressbar';

const DoctorProfile = () => {
      const { userID } = useParams();

      const [loading, setLoading] = useState(true);
      const [data, setData] = useState({});
      const [docAnalytics, setDocAnalytics] = useState({});


      const getDoctorProfile = () => {
            setLoading(true);
            fetchAllDoctorProfile(userID).then(
                  (result) => {

                        result.forEach((doc) => {
                              setData(doc.data());
                        });
                        setLoading(false)
                  },
                  (_) => setLoading(false)
            );
            doctorAnalytics(userID).then(
                  (result) => {
                        setDocAnalytics(result)
                  },
                  (_) => setLoading(false)
            );
      };

      useEffect(() => getDoctorProfile(), []);

      return !loading ? <Stack direction="column">
            <Heading>Doctor Profile</Heading>
            <div className="container">

                  <div className="horizontal-cards">
                        <div className="horizontal-card profile-card ">
                              <div className="padding-profile">
                                    <h1>Dr. {data.name} </h1>
                                    <p>Species:    {data.profile.species.length !== 0 &&
                                          data.profile.species.join(', ')}</p>
                                    <p>Status: {data.verified}</p>
                              </div>
                        </div>
                        <div className="horizontal-card smaller-cards profile-card">
                              <div className="small-card"></div>
                              <div className="small-card"></div>
                              <div className="small-card"></div>
                        </div>
                        <div className="horizontal-card"></div>
                  </div>
                  <div className="vertical-card center-content">
                        <div className="profile-image">
                              <img
                                    src={data.image}
                                    alt="Profile Image"
                                    className="profile-image"
                              />
                        </div>
                        <div className="text-fields">
                              <p> {data.name}</p>
                              <p> {data.phone}</p>
                              {/* <p>Address: 123 Main St</p> */}
                        </div>
           
                        <div className="doctor-card">
                              <div className="column">
                                    <p>{data.profile.appointmentFee}₹/Per Session</p>
                              </div>
                              <div className="column1">
                                    <div style={{ width: '50%', height: "100px",color:"black" }}>
                                    <CircularProgressbar  maxValue="5" value={data.profile.rating} text={`${data.profile.rating}`} />
                                    </div>
                              </div>
                        </div>
                        <div className="horizontal-card-2 smaller-cards2 profile-card">
                              <div className="small-card2">
                                    <p>{docAnalytics.totalTreatments}</p>
                              </div>
                             
                              <div className="small-card2"></div>
                        </div>
                        <div className="horizontal-card-2 smaller-cards2 profile-card">
                              <div className="small-card2"></div>
                             
                              <div className="small-card2"></div>
                        </div>

                  </div>
            </div>
      </Stack>
            : (
                  <Loading type="pageLoader" />
            );
}


export default DoctorProfile