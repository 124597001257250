import React from "react";
import { Document } from "@kiwicom/orbit-components/icons";
import CircleAvatar from "./CircleAvatar";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Card,
  Text,
  ButtonLink,
  CardSection,
  Button,
} from "@kiwicom/orbit-components";

// open links in new tab
const openFileHandle = (url) => {
  console.log(`url is : ${url}`);
  window.open(url);
};

const PharmacyInfoTile = ({ name, profile, approve, decline, address }) => {
  const { state, street, city, district, zipcode, phone } = address;
  return (
    <Stack direction="column">
      <Stack direction="row" justify="between" align="center">
        <CircleAvatar
          src={profile ?? "http://placeimg.com/640/480/food"}
          alt="user-profile-image"
          onClick={(_) => openFileHandle(profile)}
        />
      </Stack>
      <Text size="large" weight="bold">
        Name : {name}
      </Text>
      <Text size="normal" weight="bold">
        Address :{" "}
      </Text>
      <Text size="normal">{`${street}, ${city}, ${district}, ${state}, ${zipcode}`}</Text>
      <Text size="normal">{`Phone : ${phone}`}</Text>
    </Stack>
  );
};

const PharmacyItem = ({ pharmacy }) => {
  // destructuring
  const {
    name,
    image,
    profile: {
      license: { url: documentUrl },
    },
    address,
    id,
  } = pharmacy;

  // console.log("Pharmacy Data from Firebase : ");
  // console.log(pharmacy);
  const navigate = useNavigate();
  const navigateToViewMedicinesPage = () => {
    console.log("navigating to update medicines page");
    navigate(`/offboard-pharmacies/${id}`);
  };

  return (
    <>
      <Card
        header={
          <PharmacyInfoTile name={name} profile={image} address={address} />
        }
      >
        <Stack direction="row" justify="center">
          <Button onClick={() => navigateToViewMedicinesPage()}>
            Review Medicines
          </Button>
        </Stack>

        <CardSection
          expandable
          title="View Documents"
          icon={<Document ariaLabel="female" />}
        >
          <ButtonLink size="small" onClick={(_) => openFileHandle(documentUrl)}>
            View License
          </ButtonLink>
        </CardSection>
      </Card>
    </>
  );
};

export default PharmacyItem;
