import React, { useState } from "react";
import {
  InputField,
  Stack,
  Button,
  ButtonLink,
  ButtonGroup,
  createToast,
  Select,
  Text,
} from "@kiwicom/orbit-components";

import {
  DealsV2,
  Plus,
  Remove,
  Messages,
  Check
} from "@kiwicom/orbit-components/icons";
import { useStoreActions } from "easy-peasy";
import { useForm } from "react-hook-form";
import { weightVariants } from "../NewProduct/data";

const VariantItem = ({
  variant: {
    id,
    weight: initWeight,
    mrp: initMrp,
    margin: initMargin,
    price: initPrice,
    stock: initStock,
    weightUnit: initWeightUnit,
    discount: initDiscount,
  },
  isFinalVariant,
  addAnotherVariant,
  onDone
}) => {
  // let vetremediCost = isNaN(initMrp - initMrp * (initMargin / 100)) ?0:(initMrp - initMrp * (initMargin / 100));

  // let productPriceForCustomer =isNaN(initMrp - initMrp * (initDiscount / 100))?0:initMrp - initMrp * (initDiscount / 100);
  let vetremediCost = initMrp - (initMrp * (initMargin / 100));
    let profitForVetremedi = initMrp - vetremediCost;
    let customerDiscount = (profitForVetremedi * (initDiscount / 100));
  // console.log(initMrp,initDiscount);
  const [weight, setWeight] = useState(initWeight);
  const [mrp, setMrp] = useState(initMrp);
  const [margin, setMargin] = useState(initMargin);
  const [price, setPrice] = useState(initPrice);
  const [stock, setStock] = useState(initStock);
  const [discount, setDiscount] = useState(initDiscount);
  const [weightUnits, setWeightUnits] = useState(initWeightUnit);
  const [profitOnEachVariant, setProfitOnEachVariant] = useState( (profitForVetremedi-customerDiscount));
  const [vetremediCostForEachVariant, setVetremediCostForEachVariant] =
    useState((vetremediCost));

    

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  
  // const checkMaxDiscount = ({
  //   productDiscount
  // } = {})=>{
  //   console.log(productDiscount);
  //   if (productDiscount > 60|| productDiscount < 0) {
  //     createToast("The discount must be between 1% and 60%", {
  //       icon: <Messages />,
  //     });
  //     setDiscount(0);
  //     productDiscount = 0;
  //   }

  // };

  const checkWeight =({weight} = {}) => {
    if (weight <=0){    
      createToast("Weight should be greater than 0", {
        icon: <Messages />,
      });
    }  
  };

  const setDiscountedCustomerPrice = ({
    productMrp,
    productDiscount,
    productMargin,
  } = {}) => {
    
    // let profitPercent = productMargin - productDiscount;

    // 1.calculate product cost for vetremedi  productMrp - (productMrp * (productMargin / 100));
    // 2.calculate profit for vetremedi formula = (productMrp - vetremediCost)
    // 3.calculate discount for customer formula =  vetremediProfit *(discount%/100)
    let vetremediCost = productMrp - (productMrp * (productMargin / 100));
    let profitForVetremedi = productMrp - vetremediCost;
    let customerDiscount = (profitForVetremedi * (productDiscount / 100));
   

    if (customerDiscount > profitForVetremedi / 2) {
       createToast("The discount cannot be more than 50% of the margin", {
        icon: <Messages />,
      });
      setDiscount(0);
      productDiscount = 0;
      return
    }
    // if (profitPercent < 5) {
    //   createToast("Variation b/w Margin and Discount should be min 5%", {
    //     icon: <Messages />,
    //   });
    //   setDiscount(0);
    //   productDiscount = 0;
    // }
    
    // let vetremediCost = productMrp - (productMrp * (productMargin / 100));
    // let profitForVetremedi = productMrp - vetremediCost;
    // let customerDiscount = (profitForVetremedi * (productDiscount / 100));
   
    setPrice(productMrp-customerDiscount);

    setVetremediCostForEachVariant(isNaN(vetremediCost) ? 0 : vetremediCost);
    setProfitOnEachVariant(
      isNaN(profitForVetremedi-customerDiscount)
        ? 0
        : profitForVetremedi-customerDiscount
    );
  };

  const addVariantAction = useStoreActions((actions) => actions.appendVariant);

  const removeVariantAction = useStoreActions(
    (actions) => actions.removeVariant
  );

  const getCurrentVariant = () => ({
    id,
    weight: parseFloat(weight),
    mrp: parseInt(mrp),
    margin: parseInt(margin),
    price: parseInt(price),
    stock: parseInt(stock),
    discount: parseInt(discount),
    weightUnit: weightUnits,
  });

  const addVariant = () => {

    if (weight <=0){    
      createToast("Weight should be greater than 0", {
        icon: <Messages />,
      });
      return;
    }  
    if (price.length !== 0 && price !== 0) {
      addVariantAction(getCurrentVariant());
      addAnotherVariant();
      alert("variant added successfully!");
    } else {
      wave();
    }
  };

  const addLastVariantAndSubmit = () => {
    if (weight <=0){    
      createToast("Weight should be greater than 0", {
        icon: <Messages />,
      });
      return;
    }  
    if (price.length !== 0 && price !== 0) {
      addVariantAction(getCurrentVariant());
      onDone();
      alert("variant added successfully!");
    } else {
      wave();
    }
  };

  const removeVariant = () => {
    removeVariantAction(getCurrentVariant());
  };

  const wave = () => createToast("Please add product Price");

  return (
    <Stack direction="column" spaceAfter="largest" align="end">
      <Stack direction="row" justify="between" align="center">
      <InputField
          {...register("weight", { required: true, maxLength: 20 })}
          key="weight"
          type="number"
          label="Weight"
          placeholder="100"
          value={weight}
          error={errors.weight && "Enter required fields"}
          // prefix={"in " + weightUnits}
          onChange={(event) => {
            checkWeight({weight: event.currentTarget.value});
            setWeight(event.currentTarget.value);
          }}
        />
        <Select
          {...register("label", { required: true })}
          key="label"
          label="Weight Units"
          value={weightUnits}
          placeholder="Weight Units"
          options={weightVariants}
          error={errors.label && "Fill Required Fields"}
          onChange={(event) => setWeightUnits(event.currentTarget.value)}
        />
       
      </Stack>
      <InputField
        key="mrp"
        {...register("variantMRP", { required: true, minLength: 2 })}
        type="number"
        label="MRP"
        placeholder="100.0"
        prefix="₹"
        value={mrp}
        error={errors.variantMRP && "Enter required fields"}
        onChange={(event) => {
          setMrp(event.currentTarget.value);
          setDiscountedCustomerPrice({
            productDiscount: discount,
            productMrp: event.target.value,
            productMargin: margin,
          });
        }}
      />
      <InputField
        key="margin"
        {...register("variantMargin", { required: true })}
        type="number"
        label="Margin"
        value={margin}
        placeholder="5.0"
        prefix="%"
        error={errors.variantMargin && "Enter required fields"}
        onChange={(event) => {
          setMargin(event.currentTarget.value);
          setDiscountedCustomerPrice({
            productDiscount: discount,
            productMrp: mrp,
            productMargin: event.currentTarget.value,
          });
        }}
      />

      <InputField
        key="discount"
        type="number"
        label="Discount"
        {...register("variantDiscount", { required: true })}
        error={errors.variantDiscount && "Enter Discount"}
        placeholder="10.0"
        prefix="%"
        value={discount}
        onChange={(event) => {
          setDiscount(event.currentTarget.value);
          // checkMaxDiscount( {productDiscount: event.currentTarget.value});
          setDiscountedCustomerPrice({
            productDiscount: event.currentTarget.value,
            productMrp: mrp,
            productMargin: margin,
          });
        }}
        suffix={
          <ButtonLink
            size="small"
            onClick={(event) =>
              setDiscountedCustomerPrice({
                productDiscount: discount,
                productMargin: margin,
                productMrp: mrp,
              })
            }
          >
            <DealsV2 />
          </ButtonLink>
        }
      />
      <InputField
        // {...register("variantPrice", { required: true })}
        // error={errors.variantPrice && "Enter Customer Price"}
        key="price"
        type="number"
        label="Customer Price"
        placeholder="0"
        prefix="₹"
        disabled='{true}'
        value={price}
        onChange={(event) => setPrice(event.currentTarget.value)}
      />
      <Stack direction="column" justify="start" align="start">
        <Text align="left">
          EKHI Profit for this Product Variant ₹ {profitOnEachVariant}
        </Text>
        <Text align="left">
          EKHI Cost for this Product Variant: ₹ {vetremediCostForEachVariant}
        </Text>
      </Stack>
      <InputField
        key="stock"
        type="number"
        {...register("variantStock", { required: true })}
        error={errors.variantStock && "Enter Stock Quantity"}
        label="Stock"
        placeholder="10"
        value={stock}
        onChange={(event) => setStock(event.currentTarget.value)}
      />

     <Stack direction="row" justify="between" align="center">
        <Button
          iconLeft={<Remove />}
          type="criticalSubtle"
          onClick={(event) => removeVariant()}
        >
          Remove Variant
        </Button>
       
        {
          isFinalVariant?
          <ButtonGroup spacing="medium">
          { (
            <Button iconLeft={<Plus />} onClick={handleSubmit(addVariant)}>
              Add Another Variant
            </Button>
          )}
          { (
            <Button
              iconLeft={<Check />}
              onClick={handleSubmit(addLastVariantAndSubmit)}
            >
              Done
            </Button>
          )}
        </ButtonGroup> 
          
        :<></>
        }
      </Stack>
    </Stack>
  );
};

export default VariantItem;
