import React, { useState,useEffect } from "react";
import {
  Select,
  Heading,
  InputField,
  Textarea,
  Stack,
  Button,
  InputGroup,
  createToast,
  Text,
} from "@kiwicom/orbit-components";


import { useStoreActions, useStoreState, } from "easy-peasy";

import { categories, labels } from "./data";

import ImagePicker from "../../components/ImagePicker";
import { useForm } from "react-hook-form";

function AddProduct({ title, onDone }) {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const clearProductAction = useStoreActions(
    (actions) => actions.clearProductData
  );
  useEffect(() => {
    
    // Clear form data when the page loads
    clearProductAction();
  }, []); 



  const {
    name: initialName,
    label: initialLabel,
    category: initialCategory,
    brand: initialBrand,
    description: initialDescription,
  } = useStoreState((state) => state.product);

  const [name, setProductName] = useState(initialName ?? "");
  const [brand, setBrand] = useState(initialBrand ?? "");
  const [description, setDescription] = useState(initialDescription ?? "");
  const [category, setCategory] = useState(initialCategory ?? "");
  const [label, setLabel] = useState(initialLabel ?? "");

  const imageFiles = useStoreState((state) => state.imageFiles);
  const wave = () => createToast("Please add product Images");

  const addProductAction = useStoreActions((actions) => actions.addProduct);

  const createProduct = () => {
    console.log(imageFiles.length);
    if (imageFiles.length > 3) {
      addProductAction({
        name,
        label,
        brand,
        category,
        description,
        imageFiles,
      });
      onDone();
    } else {
      // show error toast to upload images
      wave();
    }
  };

  return (
    <>
      <Heading spaceAfter="medium">{title}</Heading>
      {/* <Grid columns="repeat(auto-fit, minmax(240px, 1fr));" columnGap="2rem"> */}
      <Stack direction="column" justify="between" spaceAfter="medium">
        <InputField
          {...register("name", {
            required: true,
            maxLength: 60,
            value: name,
            shouldUnregister: true,
          })}
          key="name"
          label="Product Name"
          placeholder=""
          value={name}
          error={errors.name && "Enter Product Name"}
          onChange={(event) => {
            if (event.currentTarget.value.length>61){
              createToast("Please limit your product name to 60 characters or less");
              return;
            }
            setProductName(event.currentTarget.value);
          }}
        />
        <InputField
          {...register("brand", {
            required: true,
            maxLength: 20,
            value: brand,
            shouldUnregister: true,
          })}
          key="brand"
          label="Brand"
          placeholder=""
          value={brand}
          error={errors.brand && "Enter Brand name"}
          onChange={(event) => {
            setBrand(event.currentTarget.value);
          }}
        />

        <InputGroup label="Product Category & Label">
          <Select
            key="category"
            value={category}
            {...register("category", { required: true })}
            placeholder="Product category"
            options={categories}
            label="Category"
            error={errors.category && "Choose Category"}
            onChange={(event) => setCategory(event.currentTarget.value)}
          />

          <Select
            {...register("label", { required: false })}
            key="label"
            value={label}
            placeholder="Product label"
            options={labels}
            error={errors.label && "Fill Required Fields"}
            label="Label"
            onChange={(event) => setLabel(event.currentTarget.value)}
          />
        </InputGroup>

        <Textarea
          {...register("desc", { required: true })}
          key="description"
          label="Description"
          value={description}
          error={errors.desc && "Fill Required Fields"}
          rows="8"
          spaceAfter="large"
          onChange={(event) => {
            setDescription(event.currentTarget.value);
          }}
        />
        <Heading align="center">Product Images</Heading>

        <ImagePicker />
        <Heading> </Heading>
        <Heading> </Heading>
        <Heading> </Heading>
        <Heading> </Heading>

        <Stack direction="row" justify="around" align="center">
          <Text type="secondary">
            You need to add at least 4 images. Please pay attention to the
            quality of pictures you add and the background color
          </Text>
        </Stack>
        <Stack direction="row" justify="around" align="center">
          <Button onClick={handleSubmit(createProduct)}>
            Proceed to Add Variants
          </Button>
        </Stack>
        <Heading> </Heading>
        <Heading> </Heading>
        <Heading> </Heading>
        <Heading> </Heading>
      </Stack>
      {/* <Stack direction="column" align="end" spaceAfter="medium">
        
         
        </Stack> */}
      {/* </Grid> */}
    </>
  );
}

export default AddProduct;
