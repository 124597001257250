import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { StoreProvider } from "easy-peasy";
// import { createToast } from "@kiwicom/orbit-components";

import { AuthContextProvider, useAuthState } from "./services/auth.service";
import store from "./store";
import Layout from "./components/Layout";

import AddProductPage from "./pages/AddProductPage";
import UpdateStockPage from "./pages/UpdateStockPage";
import ManageOrdersPage from "./pages/ManageOrdersPage";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/404";
import OffboardDoctorsPage from "./pages/OffboardDoctorsPage";
import ApproveDriversPage from "./pages/offboard_drivers";
import ApprovePharmaciesPage from "./pages/Pharmacy/OffboardPhamacies";
import ApproveMedicinesPage from "./pages/Pharmacy/update_medicines_prices";
import PaidOrdersView from "./views/PaidOrders";
import ShippedOrdersView from "./views/ShippedOrders";
import ProductsGridView from "./views/ViewProducts";
import UpdateProductStockView from "./views/UpdateProductStock";
import CustomerOrdersView from "./views/CustomerOrders";
import Dashboard from "./views/Dashboard";
import UserData from "./views/Dashboard/UserData";
import DoctorProfile from "./views/Customer_Profile/doctors";

import ManageUsersPage from "./pages/ManageUsersPage";

const AuthenticatedRoute = () => {
  let { isAuthenticated } = useAuthState();
  console.log(`AuthenticatedRoute: ${isAuthenticated}`);
  return isAuthenticated ? <Outlet /> : <LoginPage />;
};

const Home = () => {
  const { user } = useAuthState();

  useEffect(() => {
    // createToast("Login Successful");
  }, []);

  return (
    <div>
      <h1>Welcome Home</h1>
      <h2>hey {user?.email}</h2>
    </div>
  );
};

const App = () => {
  return (
    <AuthContextProvider>
      <StoreProvider store={store}>
        <Router>
          <Layout>
            <Routes>
              {/* unprotected route */}
              <Route path="login" element={<LoginPage />} />
              {/* Protected routes */}
              <Route exact path="/" element={<AuthenticatedRoute />}>
                <Route exact path="/" element={<Home />} />
                <Route exact path="dashboard" element={<Dashboard />} />

                <Route path="user-data" element={<ManageUsersPage />}>
                  <Route path=":userPath" element={<UserData />} />
                  {/* <Route
                    path="doctors"
                    element={<UserData />}
                  />
                  <Route path="parmacies" element={<UserData />} /> */}
                </Route>
                <Route path="profiles" >
                  <Route path="doctor/:userID" element={<DoctorProfile />} />
                  {/* <Route
                    path="doctors"
                    element={<UserData />}
                  />
                  <Route path="parmacies" element={<UserData />} /> */}
                </Route>



                <Route path="new-product" element={<AddProductPage />} />
                <Route
                  path="offboard-doctors"
                  element={<OffboardDoctorsPage />}
                />
                <Route
                  path="offboard-drivers"
                  element={<ApproveDriversPage />}
                />
                <Route
                  path="offboard-pharmacies"
                  element={<ApprovePharmaciesPage />}
                ></Route>
                <Route
                  path="offboard-pharmacies/:pharmacyId"
                  element={<ApproveMedicinesPage />}
                />

                <Route path="edit-stock" element={<UpdateStockPage />}>
                  <Route path="view" element={<ProductsGridView />} />
                  <Route
                    path=":productId"
                    element={<UpdateProductStockView />}
                  />
                </Route>
                <Route path="manage-orders" element={<ManageOrdersPage />}>
                  <Route path="ship" element={<PaidOrdersView />} />
                  <Route
                    path="update-shipment"
                    element={<ShippedOrdersView />}
                  />
                  <Route path="customers" element={<CustomerOrdersView />} />
                </Route>
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </Router>
      </StoreProvider>
    </AuthContextProvider>
  );
};

export default App;
