import React, { useState } from "react";

import {
  Card,
  CardSection,
  Stack,
  Badge,
  Text,
  Button,
  Dialog,
} from "@kiwicom/orbit-components";
import { getVariantsByID } from "../../services/product.service";

import ProductCoverImage from "../../components/ProductCoverImage";
import styled from "styled-components";
import { useNavigate } from "react-router";

const CardWrapper = styled.div`
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 4px 0px rgba(37, 42, 49, 0.16),
      0px 4px 8px 0px rgba(37, 42, 49, 0.12);
  }
`;

const ProductItem = ({ product, deleteProductFun }) => {
  let navigate = useNavigate();
  // let initPrice =0;
  const [price, setPrice] = useState(product.price ?? 0);
  const [showDialog, setShowDialog] = React.useState(false);
  const [chipIndex, setChipIndex] = React.useState(0);


  const editStock = () => navigate(`/edit-stock/${product.id}`);

  const getVariants = (product_id, variantId) => {
    getVariantsByID(product_id, variantId).then((v) => {
      setPrice(v.price);
    });
  };


  return (
    <CardWrapper>
      {showDialog && (
        <Dialog
          title="Delete Product"
          description="Are you sure to delete product from store?"
          renderInPortal={false}
          primaryAction={
            <Button
              onClick={() => {
                setShowDialog(false);
                deleteProductFun();
              }}
            >
              Yes
            </Button>
          }
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowDialog(false);
              }}
            >
              No
            </Button>
          }
        />
      )}
      <Card
        header={
          <ProductCoverImage
            src={product.images?.[0] ?? "http://placeimg.com/640/480/food"}
            alt={product.name}
          />
        }
      >
        <CardSection>
          <Stack
            direction="row"
            justify="between"
            align="center"
            spaceAfter="normal"
          >
            <Stack spacing="XXXSmall">
              <Text>{product.name}</Text>
              <Text weight="bold" size="large" type="success">
                ₹ {price}
              </Text>
            </Stack>
            <Badge>{product.category}</Badge>
          </Stack>
          <Stack direction="row" spacing="XSmall" spaceAfter="medium">
            {product?.variants_details?.map((v, index) => (
              
              chipIndex===index?<Badge key={v} type="dark">
                <div type="" onClick={() => {
                  setChipIndex(index);
                  getVariants(product.id, v.id);
                }}>{v.weight + `${" " + v.weight_units}`}</div>
              </Badge>:<Badge key={v} type="">
              <div type="" onClick={() => {
                setChipIndex(index);
                getVariants(product.id, v.id);
              }}>{v.weight + `${" " + v.weight_units}`}</div>
            </Badge>
            ))}
          </Stack>
          <Stack direction="row" spacing="XSmall" spaceAfter="medium">
            <Button type="critical" onClick={() => setShowDialog(true)}>
              Delete Product
            </Button>
            <Button onClick={() => editStock()}>Edit Product</Button>
          </Stack>
        </CardSection>
      </Card>
    </CardWrapper>
  );
};

export default ProductItem;
