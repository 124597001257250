import React, { useState } from "react";
import { Check, Close, Document } from "@kiwicom/orbit-components/icons";
import CircleAvatar from "./CircleAvatar";
import {
  Stack,
  Card,
  Text,
  Button,
  Dialog,
  ButtonLink,
  InputField,
  CardSection,
  ButtonGroup,
  Heading,
  Select,
} from "@kiwicom/orbit-components";
import { createToast, NotificationBadge } from "@kiwicom/orbit-components";

import { approveDriver, rejectDriver } from "../services/driver.service";
import { getNearByPharmacies } from "../firebase/geo_firebase";

// open links in new tab
const openFileHandle = (url) => {
  console.log(`url is : ${url}`);
  window.open(url);
};

const DriverInfoTile = ({ name, profile, approve, decline, address }) => {
  const { state, street, city, district, zipcode, phone } = address;
  return (
    <Stack direction="column">
      <Stack direction="row" justify="between" align="center">
        <CircleAvatar
          src={profile ?? "http://placeimg.com/640/480/food"}
          alt="user-profile-image"
          onClick={(_) => openFileHandle(profile)}
        />
        <ButtonGroup>
          <ButtonLink size="small" onClick={() => approve()}>
            <Check />
          </ButtonLink>
          <ButtonLink size="small" type="critical" onClick={() => decline()}>
            <Close />
          </ButtonLink>
        </ButtonGroup>
      </Stack>
      <Text size="large" weight="bold">
        Name : {name}
      </Text>
      <Text size="normal" weight="bold">
        Address :{" "}
      </Text>
      <Text size="normal">{`${street}, ${city}, ${district}, ${state}, ${zipcode}`}</Text>
      <Text size="normal">{`Phone : ${phone}`}</Text>
    </Stack>
  );
};

const DriverItem = ({ driver, removeDriverFromList }) => {
  // destructuring
  const {
    id,
    name,
    image,
    document: { url: documentUrl },
    address,
  } = driver;

  const [showRejectDialog, setShowRejectDialog] = React.useState(false);
  const [showApproveDialog, setShowApproveDialog] = React.useState(false);
  const [isFetchingPharmacies, setIsFetchingPharmacies] = React.useState(false);
  const [comment, setComment] = useState("");
  const [selectedPharmacy, setSelectedPharmacy] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [nearByPhamacies, setNearByPhamacies] = useState([]);

  const levels = [
    {
      label: "Beginner",
      value: "Beginner",
    },
    {
      label: "Intermediate",
      value: "Intermediate",
    },
    {
      label: "Expert",
      value: "Expert",
    },
  ];

  const taskSuccess = () => alert("Driver Reviewed successfully");
  const taskError = () => alert("Something went wrong");

  const approve = async () => {
    console.log("selected pharmacy id is :");
    console.log(selectedPharmacy);
    if (
      selectedPharmacy.toString().length === 0 ||
      selectedPharmacy === "" ||
      selectedLevel.toString().length === 0 ||
      selectedLevel === ""
    ) {
      createToast("Fill all required details to approve", {
        icon: <NotificationBadge />,
      });
    } else {
      approveDriver(id, selectedPharmacy, selectedLevel)
        .then((result) => {
          if (result) {
            taskSuccess();
            removeDriverFromList(driver);
          }
        })
        .catch((_) => {
          taskError();
        });
    }
  };

  const fetchPharmaciesToAssign = async (driverId) => {
    console.log("fetch nearby pharmacies");
    setNearByPhamacies([]);
    setIsFetchingPharmacies(true);
    await getNearByPharmacies({ driverId: driverId, searchRadius: 200 }).then(
      (pharmacyDocs) => {
        let dataFromFirestore = [];
        pharmacyDocs.forEach((doc) => {
          dataFromFirestore.push({ ...doc.data(), ref: doc.ref });
        });
        // console.log("nearby pharmacies are : ");
        // console.table(dataFromFirestore);
        let names = [];
        dataFromFirestore.forEach((eachPharmacy) => {
          const obj = {
            label: eachPharmacy.name,
            value: eachPharmacy.id,
          };
          names.push(obj);
        });
        setNearByPhamacies(names);
        setIsFetchingPharmacies(false);
      }
    );
  };

  const decline = () => {
    rejectDriver(id, comment)
      .then((result) => {
        if (result) {
          taskSuccess();
          removeDriverFromList(driver);
        }
      })
      .catch((_) => {
        taskError();
      });
  };

  return (
    <>
      {showRejectDialog && (
        <Dialog
          title={<Heading>Reject Executive</Heading>}
          description={
            <InputField
              key="comment"
              value={comment}
              placeholder="Add Comments"
              onChange={(event) => {
                setComment(event.currentTarget.value);
              }}
            />
          }
          renderInPortal={false}
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowRejectDialog(false);
              }}
            >
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              type="primary"
              onClick={() => {
                decline();
              }}
            >
              Submit Review
            </Button>
          }
        />
      )}
      {showApproveDialog && (
        <Dialog
          renderInPortal={false}
          title={<Heading>Approve Executive</Heading>}
          description={
            <Stack>
              {isFetchingPharmacies && <Text>Loading nearby Pharmacies</Text>}
              {!isFetchingPharmacies && (
                <Select
                  key="pharmacy"
                  value={selectedPharmacy}
                  placeholder="Select Pharmacy"
                  options={nearByPhamacies}
                  label="Associate Pharmacy"
                  // error={errors.category && "Choose Pharmacy"}
                  onChange={(event) =>
                    setSelectedPharmacy(event.currentTarget.value)
                  }
                />
              )}
              <Select
                key="level"
                value={selectedLevel}
                placeholder="Select Level"
                options={levels}
                label="Experience Level"
                onChange={(event) =>
                  setSelectedLevel(event.currentTarget.value)
                }
              />
            </Stack>
          }
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowApproveDialog(false);
              }}
            >
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              type="primary"
              onClick={() => {
                approve(id);
              }}
            >
              Submit Review
            </Button>
          }
        />
      )}
      <Card
        header={
          <DriverInfoTile
            name={name}
            profile={image}
            address={address}
            approve={async () => {
              await fetchPharmaciesToAssign(id);
              setShowApproveDialog(true);
            }}
            decline={() => setShowRejectDialog(true)}
          />
        }
      >
        <CardSection
          expandable
          title="View Documents"
          icon={<Document ariaLabel="female" />}
        >
          <ButtonLink size="small" onClick={(_) => openFileHandle(documentUrl)}>
            Identity Proof
          </ButtonLink>
        </CardSection>
      </Card>
    </>
  );
};

export default DriverItem;
