import { action } from "easy-peasy";

const actions = {
  fetchProducts: action((state, payload) => {
    state.products = payload;
  }),

  fetchShopOrders: action((state, payload) => {
    state.shopOrders = payload;
  }),

  addImageFiles: action((state, payload) => {
    state.imageFiles = payload;
  }),

  addProduct: action((state, payload) => {
    state.product = payload;
    console.log('added product', payload);
  }),

  clearProductData: action((state, payload) => {
    state.product = {};
    state.variants = [];
    state.imageFiles = [];
  }),

  addVariant: action((state, payload) => {
    state.variants.push(payload);
  }),

  appendVariant: action((state, payload) => {
    let newState = state.variants.filter((item) => item.id !== payload.id);
    state.variants = [...newState, payload];
  }),

  removeVariant: action((state, payload) => {
    let newState = state.variants.filter((item) => item.id !== payload.id);
    state.variants = newState;
    console.log("Item removed: ", payload.id);
  }),
};

export default actions;
