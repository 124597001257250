export const getNavData = () => [
  {
    label:"Dashboard",
    items:[
      {
        name: "Analytics",
        to: "dashboard",
      },
      {
        name: "Users",
        to: "user-data/customer",
      },
    ]
  },
  {
    label: "Shop",
    items: [
      {
        name: "Manage Orders",
        to: "manage-orders/ship",
      },
      {
        name: "New Product",
        to: "new-product",
      },
      {
        name: "Manage Products",
        to: "edit-stock/view",
      },
    ],
  },
  {
    label: "Manage Users",
    badge: "",
    items: [
      // {
      //   name: "Create Dashboard User",
      //   to: "new-user",
      // },
      {
        name: "Doctors",
        to: "offboard-doctors",
      },
      {
        name: "Pharmacies",
        to: "offboard-pharmacies",
      },
      {
        name: "Delivery Executives",
        to: "offboard-drivers",
      },
    ],
  },
  {
    label: "Admin",
    badge: "pending",
    items: [
      // {
      //   name: "Manage Medicines",
      //   to: "manage-medicines",
      // },
    ],
  },
  // {
  //   label: "Home",
  //   badge: "",
  //   items: [
  //     {
  //       name: "Doctors",
  //       to: "offboard-doctors",
  //     },
  //     {
  //       name: "Pharmacies",
  //       to: "offboard-pharmacies",
  //     },
  //     {
  //       name: "Delivery Executives",
  //       to: "offboard-drivers",
  //     },
  //   ],
  // },
];
