

import { useParams  } from 'react-router-dom';
import DoctorPage from './DoctorPage';
import Dashboard from '.';


const UserData = () => {
  const { userPath } = useParams();

    switch (userPath) {
        case 'doctors':
          return  <DoctorPage />;
        default:
          return <Dashboard/>
            break;
    }
}


export default UserData