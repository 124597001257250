import {
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { createToast } from "@kiwicom/orbit-components";


import { useState, useEffect, useContext, createContext } from "react";

import app from '../firebase/app'
console.log(`Init ${app.name}`);

const USE_EMULATOR = false;

const auth = getAuth();

if (USE_EMULATOR) {
  connectAuthEmulator(auth, "http://localhost:5002");
}

export const adminLogin = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    createToast("Login Successful");

    console.log(`${res.user.email} logged in`);
  } catch (error) {
    console.log("failed to login", error);
    alert(`${error.message}`);
  }
};

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser, setError);
    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ user, error }} {...props} />;
};

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth.user != null };
};
