import { kMedicineOrders, kShopOrdersFirestoreKey } from "../../../src/constants/keys";
import React, { useState, useEffect } from "react";
import {
    doc,
    collection,
    getDocs,
    query,
    where,
    updateDoc,
    getCountFromServer,
} from "@firebase/firestore";
import { db } from "../../../src/database";
import { createToast, NotificationBadge } from "@kiwicom/orbit-components";
// import Dashboard from '.';

export const DashboardData = async () => {

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var firstDayTimestamp = firstDay.getTime();

    const ordersQuery = query(
        collection(db, kMedicineOrders),
        where("timestamp", ">", firstDayTimestamp.toString()),
    );

    const shopOrdersQuery = query(
        collection(db, kShopOrdersFirestoreKey),
        where("timestamp", ">", firstDayTimestamp.toString()),
    );

    var delivery = 0;
    var doctor = 0;
    var vetremedi = 0;
    var pharmacy = 0;

    var shop_delivery = 0;
    var shop_vetremedi = 0;


    try {
        const querySnapshot = await getDocs(ordersQuery);
        querySnapshot.docs.forEach(order => {
            delivery += isNaN(Number(order.data()['amount']['delivery'])) ? 0 : Number(order.data()['amount']['delivery'])
            doctor += isNaN(Number(order.data()['amount']['doctor'])) ? 0 : Number(order.data()['amount']['doctor'])
            vetremedi += isNaN(Number(order.data()['amount']['org'])) ? 0 : Number(order.data()['amount']['org'])
            pharmacy += isNaN(Number(order.data()['amount']['pharmacy'])) ? 0 : Number(order.data()['amount']['pharmacy'])
        });

        const shopQuerySnapshot = await getDocs(shopOrdersQuery);
        shopQuerySnapshot.docs.forEach(order => {
            shop_delivery += isNaN(Number(order.data()['order']['delivery_fee'])) ? 0 : Number(order.data()['order']['delivery_fee'])
            shop_vetremedi += isNaN(Number(order.data()['order']['item_total'])) ? 0 : Number(order.data()['order']['item_total'])
        });






        var totalOrdersTillDate = querySnapshot.docs.length;
        return {
            "delivery": delivery,
            "doctor": doctor,
            "vetremedi": vetremedi,
            "pharmacy": pharmacy,
            "totalOrdersTillDate": totalOrdersTillDate,
            "shop_delivery": shop_delivery,
            "shop_vetremedi": shop_vetremedi,
            "shop_orders":{
                "shop_delivery": shop_delivery,
                "shop_vetremedi": shop_vetremedi,
            }
        };
    } catch (error) {
        console.log(error);
        createToast("Something went wrong while fetching doctors", {
            icon: <NotificationBadge />,
        });
        return Promise.reject("Something went wrong", error);
    }

}



