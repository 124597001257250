/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import {
  Heading,
  InputField,
  Button,
  Layout,
  Stack,
} from "@kiwicom/orbit-components";
import { useForm } from "react-hook-form";
import { adminLogin } from "../services/auth.service";
import {validateEmail} from '../utils/validation'

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleLoginSubmit = useCallback(async (e) => {
    let creds = [watch("adminMail"), watch("adminPassword")];
    const [email, password] = creds;

    console.log(email, password);
    await adminLogin(email, password);
  }, []);

  return (
    <form>
      <Layout type="MMB">
        <Stack justify="center" direction="column">
          <Heading>Login</Heading>
          <InputField
            {...register("adminMail", {
              required: true,
              validate: value => validateEmail(value)
            })}
            key="email"
            label="Email"
            placeholder="Email Address"
            error={errors.adminMail && "Enter Your Email"}
          />
          <InputField
            {...register("adminPassword", {
              required: true,
              maxLength: 20,
            })}
            key="password"
            type="password"
            label="Password"
            placeholder="Your Password"
            error={errors.adminPassword && "Enter Your Password"}
          />
          <Button onClick={handleSubmit(handleLoginSubmit)}>LOGIN</Button>
        </Stack>
      </Layout>
    </form>
  );
};

export default LoginPage