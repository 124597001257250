import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Picture from "../Picture";
import { Button, Heading, Stack } from "@kiwicom/orbit-components";
import { SelectedImagesWrapper } from "./styles";
const initialState = [];

const ImagePicker = () => {
  const fileTypes = ".png,.jpg,.jpeg,.webp";
  const [images, setImages] = useState(initialState);
  const [files, setFiles] = useState(
    useStoreState((state) => state.imageFiles)
  );

  const addImageFiles = useStoreActions((actions) => actions.addImageFiles);

  useEffect(() => {
    console.log("added files to store", files);
    addImageFiles(files);
  }, [addImageFiles, files]);

  const renderImages = async (files) => {
    // setImages([]);
    // let length = files.length;
    let imageFiles = [];

    // for (let i = 0; i < length; i++) {
    let file = files[0];
    file["id"] = Math.random();
    imageFiles.push(file);

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImages((prevState) => [...prevState, reader.result]);
    };
    // }

    setFiles((prevState) => [...prevState, file]);
  };

  return (
    <>
      <SelectedImagesWrapper>
        {files.length > 0 &&
          files.map((file, index) => (
            <Picture
              key={`product-placeholder-${index}`}
              alt={`product-placeholder-${index}`}
              src={URL.createObjectURL(file)}
            />
          ))}
      </SelectedImagesWrapper>
      <Heading> </Heading>
      <Heading> </Heading>
      <Heading> </Heading>
      <Heading> </Heading>
      {images && <Heading> </Heading>}

      <Stack direction="row" justify="center" align="center">
        <label className="custom-file-upload">
          <input
            type="file"
            accept={fileTypes}
            onChange={(event) => renderImages(event.currentTarget.files)}
          />
          + Add Image
        </label>
        {files.length > 0 && (
          <Button
            type="critical"
            onClick={() => {
              setFiles([]);
              setImages([]);
            }}
          >
            Clear All Images
          </Button>
        )}
      </Stack>
    </>
  );
};

export default ImagePicker;
