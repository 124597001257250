import React, { useEffect, useState } from "react";
import { TableCell, TableFooter, TableRow } from "@kiwicom/orbit-components";

const OrderFooter = ({ length, prefix, suffix }) => {
  const [children, setChildren] = useState([]);

  useEffect(() => {
    let cells = [];

    for (let i = 0; i < (length-2); i++) cells.push(<TableCell key={i}></TableCell>);

    setChildren(cells);
  }, [length]);

  return (
    <TableFooter>
      <TableRow>
        <TableCell>{prefix}</TableCell>
        {children}
        <TableCell>{suffix}</TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default OrderFooter;
