import { defaultTheme } from "@kiwicom/orbit-components";
import styled from "styled-components";

const AddVariantWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  cursor: pointer;
  border: .125rem dashed ${({ theme }) => theme.orbit.paletteCloudDark};
`;

AddVariantWrapper.defaultProps = {
    theme: defaultTheme,
  };

export default AddVariantWrapper;
