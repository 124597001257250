import React from "react";
import {
  Table,
  Text,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@kiwicom/orbit-components";

const VariantInfo = ({ variants }) => {
  return (
    <>
      <Table type="secondary" striped={false}>
        <TableHead>
          <TableCell>Variant</TableCell>
          <TableCell>Description</TableCell>
        </TableHead>
        <TableBody>
          {variants.map(
            ({ weight, mrp, price, stock, margin, discount, weightUnit }) => (
              <TableRow>
                <TableCell> {weight + " " + weightUnit} </TableCell>
                <TableCell>
                  <Text spaceAfter="small">MRP : ₹ {mrp}</Text>
                  <Text spaceAfter="small">Margin : {margin} %</Text>
                  <Text spaceAfter="small">Discount : {discount} %</Text>
                  <Text spaceAfter="small">Customer Price : ₹ {price }</Text>
                  <Text spaceAfter="small">
                    VetRemedi Cost : ₹ {( mrp - (mrp * (margin / 100)))}
                  </Text>
                  <Text spaceAfter="small">
                    VetRemedi Profit : ₹
                    {mrp - (mrp - (mrp * (margin / 100)))  - (((mrp - (mrp - (mrp * (margin / 100)))) * (discount / 100)))}
                  </Text>
                  <Text spaceAfter="small">Stock Count : {stock}</Text>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default VariantInfo;
