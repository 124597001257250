import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@kiwicom/orbit-components";
import styled from "styled-components";

const SidebarTileItemWrapper = styled.div`
  a {
    text-decoration: none;
  }
  
  &:hover{
    text-decoration: underline;
  }
`;

const SidebarTileItem = ({ to, title }) => {
  return (
    <SidebarTileItemWrapper>
      <Link to={to}>
        <Text size="normal" type="secondary" > {title} </Text>
      </Link>
    </SidebarTileItemWrapper>
  );
};

export default SidebarTileItem;
