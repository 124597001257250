import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  connectStorageEmulator,
} from "firebase/storage";

const USE_EMULATOR = false;

const storage = getStorage();

if (USE_EMULATOR) {
  connectStorageEmulator(storage, "localhost", 9199);
}

export const uploadImages = async (imageFiles) => {
  let images = [];

  for (let i = 0; i < imageFiles.length; i++) {
    const image = imageFiles[i]
    
    const storageRef = ref(storage, `images/${image.name}`);

    const uploadTask = await uploadBytes(storageRef, image);

    const downloadURL = await getDownloadURL(uploadTask.ref);

    images.push(downloadURL);
  }

  return images;
};
