import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  Box,
  Button,
  Stack,
  Dialog,
  Heading,
  Loading,
  Text,
  ButtonGroup,


} from "@kiwicom/orbit-components";

import ViewProduct from "./ProductInfo";
import VariantInfo from "./VariantInfo";
import { addProduct } from "../../services/product.service";
import { useNavigate } from "react-router-dom";

const ReviewProduct = ({ title,onEditVariants }) => {
  const navigate = useNavigate();

  const storedVariants = useStoreState((state) => state.variants);

  const variants = storedVariants.filter((item) => item.name !== "");

  const storedProduct = useStoreState((state) => state.product);

  const clearProductAction = useStoreActions(
    (actions) => actions.clearProductData
  );

  const [showDialog, setShowDialog] = React.useState(false);
  const [busy, setBusy] = React.useState(false);

  const uploadProduct = () => {
    const { name, label, brand, category, description, imageFiles } =
      storedProduct;
    setBusy(true);
    addProduct({
      name,
      brand,
      label,
      category,
      description,
      variants: variants,
      imageFiles,
    }).then(() => {
      alert("Products added successfully");
      clearProductAction();
      setBusy(true);
      navigate("/");
    });
  };

  return busy ? (
    <Stack direction="column" justify="between" align="center">
      <Loading type="pageLoader" />
      <Text>
        {" "}
        Submitting data Please Wait... Uploading Images might take time based on
        your internet connection
      </Text>
    </Stack>
  ) : (
    <Stack>
      <Heading>{title}</Heading>
      {showDialog && (
        <Dialog
          title="Review Product"
          description="Are you sure to upload product data?"
          renderInPortal={false}
          primaryAction={
            <Button
              onClick={() => {
                setShowDialog(false);
                uploadProduct();
              }}
            >
              Yes
            </Button>
          }
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowDialog(false);
              }}
            >
              No
            </Button>
          }
        />
      )}
      <ViewProduct product={storedProduct} />
      <VariantInfo variants={variants} />
      <Box
        margin={{
          top: "XLarge",
        }}
        padding={{
          top: "XLarge",
        }}
      >
        <ButtonGroup spacing="medium">
          <Button
            spaceAfter="largest"
            onClick={() => {
              // setShowDialog(true);
              onEditVariants();
            }}
          >
            Edit Variants
          </Button>
          {!showDialog && (
            <Button
              spaceAfter="largest"
              onClick={() => {
                setShowDialog(true);
              }}
            >
              Continue
            </Button>
          )}
        </ButtonGroup>

      </Box>
    </Stack>
  );
};

export default ReviewProduct;
