import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  ListItem,
  List,
  InputField,
  ButtonLink,
  Button,
  Dialog,
  Text,
} from "@kiwicom/orbit-components";

import { addTrackingNumber } from "../../services/order.service";

const OrderItem = ({ onDone, order }) => {
  const {
    id: order_id,
    timestamp,
    items,
    address: { contact, state, zipcode },
    user: { name, phone },
  } = order;

  const [showDialog, setShowDialog] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");

  const addTrackingInfo = async () => {
    setShowDialog(false);
    let result = await addTrackingNumber(order_id, trackingNumber);

    console.log(result);

    if (result) {
      alert("Tracking number updated successfully");
      onDone && onDone();
    }
  };

  return (
    <TableRow>
      {showDialog && (
        <Dialog
          title="Confirm"
          description="Are you sure to add the tracking number?"
          renderInPortal={false}
          primaryAction={
            <Button
              onClick={() => {
                addTrackingInfo();
              }}
            >
              Accept
            </Button>
          }
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowDialog(false);
              }}
            >
              Cancel
            </Button>
          }
        />
      )}
      <TableCell key="user_info">
        <Text key="name">{name}</Text>
        <Text key="phone">{phone}</Text>
      </TableCell>
      <TableCell>{timestamp}</TableCell>
      <TableCell>
        <List>
          {items.map((item, i) => (
            <ListItem key={i}>
              {item.name} x{item.quantity}
            </ListItem>
          ))}
        </List>
      </TableCell>
      <TableCell key="address">
        <Text>{contact}</Text>
        <Text>{state}</Text>
        <Text>{zipcode}</Text>
      </TableCell>
      <TableCell>
        <InputField
          value={trackingNumber}
          onChange={(event) => setTrackingNumber(event.currentTarget.value)}
          suffix={
            <ButtonLink
              onClick={() => {
                setShowDialog(true);
              }}
            >
              Add
            </ButtonLink>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default OrderItem;
