

import { useLocation, useParams } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { fetchAllDoctors, fetchDoctors } from "../../services/doctor.service";
import {
    Stack,
    Grid,
    Loading,
    Button,
    Box,
    Heading,
    Text,
} from "@kiwicom/orbit-components";

import { Reload } from "@kiwicom/orbit-components/icons";
import DoctorItem from "../../components/DoctorItem";
// import Dashboard from '.';

const DoctorPage = () => {

    const [doctors, setDoctors] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);
    const [loading, setLoading] = useState(true);

    const populateDocs = (docs) => {
        if (docs === undefined) return setLoading(false);
        setLastDoc(docs[docs.length - 1]);

        let data = [];

        docs.forEach((doc) => {
            data.push({ ...doc.data(), ref: doc.ref });
        });

        setDoctors((prevData) => [...prevData, ...data]);
        setLoading(false);
    };

    const removeDoctorFromList = (doctor) => {
        setLoading(true);
        const ind = doctors.indexOf(doctor);
        console.log(`index of the doctor is: ${ind}`);
        if (ind > -1) {
            setDoctors(doctors.filter((item) => item !== doctor));
            console.log("Doctor removed from the list");
        }
        setLoading(false);
    };

    const getDoctors = () => {
        setLoading(true);
        fetchAllDoctors(lastDoc).then(
            (result) => {
                populateDocs(result);
            },
            (_) => setLoading(false)
        );
    };

    useEffect(() => getDoctors(), []);




    return !loading ? (
        <Stack direction="column">
            <Heading>Approved Doctors</Heading>
            <Grid
                largeDesktop={{ columns: "repeat(3, 1fr)" }}
                mediumMobile={{ columns: "repeat(2, 1fr)" }}
                rowGap="1.5rem"
                columnGap="1rem"
            >
                {doctors.length === 0 && <Text>No Doctors </Text>}
                {doctors.length !== 0 &&
                    doctors.map((doctor) => (
                        <DoctorItem
                            key={doctor.id}
                            doctor={doctor}
                            removeDoctorFromList={() => removeDoctorFromList(doctor)}
                        />
                    ))}
            </Grid>
            {doctors.length > 3 && (
                <Box padding={{ bottom: "XXLarge" }}>
                    <Button
                        iconLeft={<Reload />}
                        type="secondary"
                        onClick={() => getDoctors()}
                        spaceAfter="largest"
                    >
                        Load More
                    </Button>
                </Box>
            )}
        </Stack>
    ) : (
        <Loading type="pageLoader" />
    );


}


export default DoctorPage