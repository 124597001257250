import { kDoctorsFirestoreKey as root ,kTreatments  } from "../../../constants/keys";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  getCountFromServer,
} from "@firebase/firestore";
import { db } from "../../../database";
import { createToast, NotificationBadge } from "@kiwicom/orbit-components";

export const fetchAllDoctorProfile = async (id) => {
    const ordersQuery = query(
        collection(db, root),
        where("id", "==", id),
    );

    try {
        const querySnapshot = await getDocs(ordersQuery);
        console.table(querySnapshot.docs);
        return querySnapshot.docs;
    } catch (error) {
        console.log(error);
        createToast("Something went wrong while fetching doctors", {
            icon: <NotificationBadge />,
        });
        return Promise.reject("Something went wrong", error);
    }
};

export const doctorAnalytics = async (id) => {

    const coll = collection(db, kTreatments);
    const q = query(coll, where("doctor.id", "==", id),where("status","==",'closed'));
    const snapshot = await getCountFromServer(q);

   
    const q2 = query(coll, where("doctor.id", "==", id),where("status","==",'closed'),where("payment","==",null));
    const snapshot2 = await getCountFromServer(q2);

    // const q2 = query(coll, where("doctor.id", "==", id),where("status","==",'closed'),where("payment","==",null));
    // const snapshot2 = await getCountFromServer(q2);


    try {
        console.log('countOfDocs: ', snapshot.data().count);
        console.log('countOfDocs: ', snapshot2.data().count);

        return {
            totalTreatments:snapshot.data().count,
            
        }

    } catch (error) {
        console.log(error);
        createToast("Something went wrong while fetching doctors", {
            icon: <NotificationBadge />,
        });
        return Promise.reject("Something went wrong", error);
    }
};