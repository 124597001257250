import {
  doc,
  collection,
  limit,
  getDocs,
  orderBy,
  query,
  startAfter,
  where,
  runTransaction,
} from "@firebase/firestore";

import { db } from "../database";

import { kShopOrdersFirestoreKey as root } from "../constants";

export const fetchOrders = async (lastVisible, status) => {
  if (lastVisible === undefined)
    return Promise.reject("there are no orders left");

  const ordersQuery = query(
    collection(db, root),
    orderBy("timestamp"),
    where("status", "==", status),
    startAfter(lastVisible),
    limit(5)
  );

  try {
    const querySnapshot = await getDocs(ordersQuery);
    return querySnapshot.docs;
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const fetchCustomerOrders = async (lastVisible, customerId) => {
  if (lastVisible === undefined)
    return Promise.reject("there are no orders left");

  const ordersQuery = query(
    collection(db, root),
    orderBy("timestamp"),
    where("user.id", "==", customerId),
    startAfter(lastVisible),
    limit(5)
  );

  try {
    const querySnapshot = await getDocs(ordersQuery);
    return querySnapshot.docs;
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const addTrackingNumber = async (orderId, trackingNumber) => {
  const docRef = doc(db, root, orderId);

  try {
    const isUpdated = await runTransaction(db, async (transaction) => {
      const orderDoc = await transaction.get(docRef);

      const { status } = orderDoc.data();

      if (status === "shipped") {
        transaction.set(
          docRef,
          {
            status: "transit",
            order: {
              delivery_tracking: trackingNumber,
            },
          },
          { merge: true }
        );

        return true;
      } else {
        return Promise.reject("Sorry! The tracking number can't be added");
      }
    });

    return isUpdated;
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const updateOrderStatus = async (orders, orderStatus) => {
  if (orders === null || orders.length === 0) return alert("None selected");

  for (let i = 0; i < orders.length; i++) {
    const docRef = doc(db, root, orders[i]);

    try {
      const isUpdated = await runTransaction(db, async (transaction) => {
        const orderDoc = await transaction.get(docRef);

        const { status } = orderDoc.data();

        if (status === "paid") {
          transaction.update(docRef, { status: orderStatus });
          return true;
        } else if (status === "cancelled")
          return Promise.reject("Sorry! The order was cancelled");
        else
          return Promise.reject(
            "Sorry! The order can't be shipped due to some reason"
          );
      });

      return isUpdated;
    } catch (error) {
      console.log(error);
      return Promise.reject("Sorry,one of the order has been cancelled", error);
    }
  }
};
