import {
  kPharmaciesFirestoreKey,
  kPharmacyMedicinesFirestoreKey,
} from "../constants/keys";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  doc,
} from "@firebase/firestore";
import { db } from "../database";
import { createToast, NotificationBadge } from "@kiwicom/orbit-components";

export const fetchPharmacyMedicines = async (pharmacyId) => {
  let pharmacyMedicinesSubCollectionPath = `${kPharmaciesFirestoreKey}/${pharmacyId}/${kPharmacyMedicinesFirestoreKey}`;
  const medicinesQuery = query(
    collection(db, pharmacyMedicinesSubCollectionPath)
  );

  try {
    const querySnapshot = await getDocs(medicinesQuery);
    console.log("fetched medicines under this pharmacy successfully");
    return querySnapshot.docs;
  } catch (error) {
    console.log(error);
    createToast("Something went wrong while fetching pharmacy medicines", {
      icon: <NotificationBadge />,
    });
    return Promise.reject("Something went wrong", error);
  }
};

export const approveAndUpdateMedicineData = async ({
  pharmacyId,
  medicineId,
  price,
  margin,
  discount,
}) => {
  let pharmacyMedicinesSubCollectionPath = `${kPharmaciesFirestoreKey}/${pharmacyId}/${kPharmacyMedicinesFirestoreKey}`;
  const medicineRef = doc(db, pharmacyMedicinesSubCollectionPath, medicineId);

  try {
    await updateDoc(medicineRef, {
      price: parseInt(price),
      margin: parseInt(margin),
      discount: parseInt(discount),
    });
    createToast("Updated Medicine Info Successfully", {
      icon: <NotificationBadge />,
    });
    return true;
  } catch (error) {
    console.log(error);
    createToast("Something went wrong while updating pharmacy medicine info", {
      icon: <NotificationBadge />,
    });
    return Promise.reject("Something went wrong", error);
  }
};
