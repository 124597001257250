import { kDoctorsFirestoreKey as root } from "../constants/keys";
import {
  doc,
  collection,
  limit,
  getDocs,
  orderBy,
  query,
  startAfter,
  where,
  updateDoc,
} from "@firebase/firestore";
import { db } from "../database";
import { createToast, NotificationBadge } from "@kiwicom/orbit-components";

const VerificationStatus = {
  approved: "approved",
  declined: "declined",
  review: "review",
};

Object.freeze(VerificationStatus);

export const fetchDoctors = async (lastVisible) => {
  if (lastVisible === undefined)
    return Promise.reject("there are no doctors left");

  const ordersQuery = query(
    collection(db, root),
    orderBy("name"),
    where("verified", "==", VerificationStatus.review),
    startAfter(lastVisible),
    limit(5)
  );

  try {
    const querySnapshot = await getDocs(ordersQuery);
    console.table(querySnapshot.docs);
    return querySnapshot.docs;
  } catch (error) {
    console.log(error);
    createToast("Something went wrong while fetching doctors", {
      icon: <NotificationBadge />,
    });
    return Promise.reject("Something went wrong", error);
  }
};


export const fetchAllDoctors = async (lastVisible) => {
  if (lastVisible === undefined)
    return Promise.reject("there are no doctors left");

  const ordersQuery = query(
    collection(db, root),
    orderBy("name"),
    where("verified", "==", VerificationStatus.approved),
    startAfter(lastVisible),
    limit(5)
  );

  try {
    const querySnapshot = await getDocs(ordersQuery);
    console.table(querySnapshot.docs);
    return querySnapshot.docs;
  } catch (error) {
    console.log(error);
    createToast("Something went wrong while fetching doctors", {
      icon: <NotificationBadge />,
    });
    return Promise.reject("Something went wrong", error);
  }
};


export const approveDoctor = async (doctorId, rating, fee, level) => {
  const docRef = doc(db, root, doctorId);

  try {
    await updateDoc(docRef, {
      verified: VerificationStatus.approved,
      "profile.rating": parseInt(rating),
      "profile.appointmentFee": parseFloat(fee),
      level: level,
    });

    console.log("doctor got approved");

    return true;
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};

export const rejectDoctor = async (doctorId, comments) => {
  const docRef = doc(db, root, doctorId);

  try {
    await updateDoc(docRef, {
      verified: VerificationStatus.declined,
      "profile.comments": comments,
    });

    console.log("doctor got declined");

    return true;
  } catch (error) {
    console.log(error);
    return Promise.reject("Something went wrong", error);
  }
};
