import React from "react";
import { Badge, Collapse, Heading, Inline, Stack } from "@kiwicom/orbit-components";
import SidebarTileItem from "./SidebarTileItem";

const SidebarTile = ({ tile }) => {
  return (
    <Collapse
      initialExpanded={true}
      label={
        <Inline align="center" spacing="small">
          <Heading type="title3">{tile.label ?? "Label"}</Heading>
          {tile.badge && <Badge>{tile.badge}</Badge>}
        </Inline>
      }
    >
      <Stack>
        {tile.items.map((item) => (
          <SidebarTileItem key={item.to} to={item.to} title={item.name} />
        ))}
      </Stack>
    </Collapse>
  );
};

export default SidebarTile;
