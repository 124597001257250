/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Stack,
  Loading,
  Button,
  createToast,
  NotificationBadge,
  Heading,
  Dialog,
  InputField,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Inline,
  Text,
} from "@kiwicom/orbit-components";
import {
  CheckCircle,
  CircleEmpty,
  Edit,
} from "@kiwicom/orbit-components/icons";

import { useNavigate, useParams } from "react-router-dom";
import {
  fetchPharmacyMedicines,
  approveAndUpdateMedicineData,
} from "../../services/medicines.service";
import {
  rejectPharmacy,
  approvePharmacy,
  fetchPharmacyDetails,
} from "../../services/pharmacy.service";

import validator from "validator";

const ApproveMedicines = () => {
  ///TODO: on init check if pharmacy is already approved or not
  // if it's already approved then reject the navigation and display popup saying that pharmacy is already approved

  const [medicines, setMedicines] = useState([]);
  // const [approvedMedicineIds, setApprovedMedicineIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pharmacyName, setPharmacyName] = useState("");
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedMedicineId, setSelectedMedicineId] = useState("");
  const [price, setPrice] = useState();
  const [mrp, setMRP] = useState();
  const [margin, setMargin] = useState();
  const [discount, setDiscount] = useState();
  const [ekhiProfitForCurrentMedicine, setEkhiProfitForCurrentMedicine] =
    useState(0);
  const navigate = useNavigate();

  const { pharmacyId } = useParams();

  const fetchPharmacyMedicinesOnInit = async () => {
    setLoading(true);
    await fetchPharmacyMedicines(pharmacyId).then((result) => {
      console.log(result);
      let medicineDocs = result;
      let data = [];
      medicineDocs.forEach((eachMedicine) => {
        data.push({ ...eachMedicine.data(), ref: eachMedicine.ref });
      });

      setMedicines(data);
    });

    await fetchPharmacydetails();

    setLoading(false);
  };
  const fetchPharmacydetails = async () => {
    try {
      await fetchPharmacyDetails(pharmacyId).then((res) => {
        let pharmacyData = res.data();
        console.log(`current pharmacy data having id : ${pharmacyId}`);
        console.log(pharmacyData);
        setPharmacyName(pharmacyData["name"]);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => fetchPharmacyMedicinesOnInit(), []);

  // REJECT PHARMACY
  const decline = () => {
    rejectPharmacy(pharmacyId, comment)
      .then((result) => {
        if (result) {
          createToast("Pharmacy Rejected", {
            icon: <NotificationBadge />,
          });
          navigate("/offboard-pharmacies");
        }
      })
      .catch((_) => {
        createToast("Something went wrong", {
          icon: <NotificationBadge />,
        });
      });
  };

  // APPROVE PHARMACY
  const approve = () => {
    approvePharmacy(pharmacyId)
      .then((res) => {
        if (res) {
          createToast("Pharmacy Approved Successfully", {
            icon: <NotificationBadge />,
          });
          navigate("/offboard-pharmacies");
        }
      })
      .catch((_) => {
        createToast("Something went wrong while approving pharmacy", {
          icon: <NotificationBadge />,
        });
      });
  };

  // UPDATE AND APPROVE EACH MEDICINE
  const updateMedicineData = ({ medicineId }) => {
    // check for empty fields
    if (price === undefined || discount === undefined || margin === undefined) {
      createToast("Enter all required fields", {
        icon: <NotificationBadge />,
      });
      return;
    }
    let digits_only = (val) => val - 0 === val && ("" + val).trim().length > 0;
    // [...string].every((c) => "0123456789".includes(c));

    if (
      price.toString().length === 0 ||
      margin.toString().length === 0 ||
      discount.toString().length === 0
    ) {
      createToast("Please enter all the mandatory fields.", {
        icon: <NotificationBadge />,
      });
      return;
    }
    if (margin > 99) {
      createToast("Margin Percentage should be less than 100", {
        icon: <NotificationBadge />,
      });
      return;
    }
    if (discount > 50) {
      createToast("Max Discount will be  50", {
        icon: <NotificationBadge />,
      });
      return;
    }

    if (
      validator.isDecimal(price) &&
      digits_only(margin) &&
      digits_only(discount)
    ) {
      console.log(price);
      console.log(margin);
      console.log(discount);
      createToast("Characters are not allowed.", {
        icon: <NotificationBadge />,
      });
      return;
    }
    approveAndUpdateMedicineData({
      pharmacyId: pharmacyId,
      medicineId: medicineId,
      price: price,
      discount: discount,
      margin: margin,
    })
      .then((result) => {
        if (result) {
          setSelectedMedicineId("");
          setPrice();
          setEkhiProfitForCurrentMedicine(0);
          setMargin();
          setMRP();
          setDiscount();
          setShowUpdateDialog(false);
          fetchPharmacyMedicinesOnInit();
        }
      })
      .catch((_) => {
        /// something went wrong while updating medicine info
      });
  };
  const getPercentage = ({ percent, amount } = {}) =>
    Number(((percent * amount) / 100).toFixed(2));

  // calculate each medicine price based on margin, mrp, discount
  const calculateMedicinePrice = ({ margin, discount } = {}) => {
    // call this method when margin or discount value got changed
    let medicineMarginAmount = getPercentage({
      percent: margin ?? 0,
      amount: mrp,
    });
    // amount goes to pharmacy = mrp - medicineMarginAmount

    let discountToCustomer = getPercentage({
      percent: discount ?? 0,
      amount: medicineMarginAmount,
    });
    let ekhiProfit = Number(
      (medicineMarginAmount - discountToCustomer).toFixed(2)
    );
    setEkhiProfitForCurrentMedicine(ekhiProfit);

    let actualMedicinePrice = mrp - discountToCustomer;

    // amount goes to ekhi = medicineMarginAmount - discountToCustomer
    // customer pays for the medicine : actualMedicinePrice
    setPrice(actualMedicinePrice.toFixed(2));
  };

  return (
    <>
      {showRejectDialog && (
        <Dialog
          title={<Heading>Reject Pharmacy</Heading>}
          description={
            <InputField
              key="comment"
              value={comment}
              placeholder="Add Comments"
              onChange={(event) => {
                setComment(event.currentTarget.value);
              }}
            />
          }
          renderInPortal={false}
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowRejectDialog(false);
              }}
            >
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              type="primary"
              onClick={() => {
                decline();
              }}
            >
              Reject & Submit Review
            </Button>
          }
        />
      )}
      {showUpdateDialog && (
        <Dialog
          title={<Heading>Approve Medicine</Heading>}
          description={
            <Stack>
              <InputField
                key="medicine_mrp"
                type="number"
                label="Medicine MRP (₹)"
                value={mrp}
                placeholder="₹0"
                disabled="true"
              />
              <InputField
                key="price"
                disabled="true"
                label="Medicine Price (₹) *"
                value={price}
                placeholder="₹0"
              />
              <Text>
                EKHI Profit for this medicine ₹{ekhiProfitForCurrentMedicine}
              </Text>
              <InputField
                key="margin"
                label="Margin (%) *"
                type="number"
                value={margin}
                placeholder="₹0"
                onChange={(event) => {
                  setMargin(event.currentTarget.value);
                  calculateMedicinePrice({
                    discount: discount,
                    margin: event.currentTarget.value,
                  });
                }}
              />
              <InputField
                key="discount"
                label="Discount (%) *"
                value={discount}
                type="number"
                placeholder="%0"
                onChange={(event) => {
                  setDiscount(event.currentTarget.value);
                  calculateMedicinePrice({
                    margin: margin,
                    discount: event.currentTarget.value,
                  });
                }}
              />
            </Stack>
          }
          renderInPortal={false}
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowUpdateDialog(false);
                setSelectedMedicineId("");
                setPrice();
                setEkhiProfitForCurrentMedicine(0);
                setMRP();
                setMargin();
                setDiscount();
              }}
            >
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              type="primary"
              onClick={() =>
                updateMedicineData({ medicineId: selectedMedicineId })
              }
            >
              Approve & Update Price
            </Button>
          }
        />
      )}
      {showApproveDialog && (
        <Dialog
          title={<Heading>Approve Pharmacy</Heading>}
          renderInPortal={false}
          description="Are you sure you want to approve this pharmacy ?"
          secondaryAction={
            <Button
              type="secondary"
              onClick={() => {
                setShowApproveDialog(false);
              }}
            >
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              type="primary"
              onClick={() => {
                approve();
              }}
            >
              Approve
            </Button>
          }
        />
      )}
      {!loading ? (
        <Stack>
          <Heading>{pharmacyName}</Heading>
          <Heading>Update Medicine Price Info</Heading>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell as="th">Name</TableCell>
                {/* <TableCell as="th">Medicine ID</TableCell> */}
                <TableCell as="th">Category</TableCell>
                <TableCell as="th">Package</TableCell>
                <TableCell as="th">Discount</TableCell>
                <TableCell as="th">Margin</TableCell>
                <TableCell as="th">MRP</TableCell>
                <TableCell as="th">Price</TableCell>
                <TableCell as="th">Approved Status</TableCell>
              </TableRow>
            </TableHead>
            {medicines.map((medicine) => (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Inline align="center">{medicine.name ?? ""}</Inline>
                  </TableCell>
                  <TableCell>{medicine.category ?? "--"}</TableCell>
                  <TableCell>{medicine.package ?? "--"}</TableCell>
                  <TableCell>{medicine.discount ?? "--"}</TableCell>
                  <TableCell>{medicine.margin ?? "--"}</TableCell>
                  <TableCell>₹{medicine.mrp ?? ""}</TableCell>
                  <TableCell>{medicine.price ?? "--"}</TableCell>
                  <TableCell align="center">
                    {medicine.price != null ? (
                      <div
                      // style={{ cursor: "pointer" }}
                      >
                        <CheckCircle color="success" />
                        <span
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={() => {
                            setShowUpdateDialog(true);
                            setMRP(medicine.mrp);
                            setSelectedMedicineId(medicine.medicine_id);
                            setMargin(medicine.margin ?? 0);
                            setPrice(medicine.price ?? 0);
                          }}
                        >
                          <Edit color="success" />
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowUpdateDialog(true);
                          setSelectedMedicineId(medicine.medicine_id);
                          setMRP(medicine.mrp);
                          setMargin(medicine.margin ?? 0);
                        }}
                      >
                        <CircleEmpty />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>

          <Stack direction="row" justify="center">
            <Button
              onClick={() => setShowRejectDialog(true)}
              type="criticalSubtle"
            >
              Reject Pharmacy
            </Button>
            <Button onClick={() => setShowApproveDialog(true)} type="primary">
              Approve Pharmacy
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Loading type="pageLoader" />
      )}
    </>
  );
};

export default ApproveMedicines;
