import { Heading, LinkList, Stack } from "@kiwicom/orbit-components";
import React from "react";
import { Outlet } from "react-router";
import LinkTile from "../components/LinkTile";

const ManageUsersPage = () => {
  return (
    <Stack>
      <Heading>Manage Users</Heading>
      <LinkList direction="row">
        <LinkTile to="customers" title="Customers" />
        <LinkTile to="doctors" title="Doctors" />
        <LinkTile to="pharmacies" title="pharmacies" />
      </LinkList>
      <Outlet />
    </Stack>
  );
};

export default ManageUsersPage;
