import { TableCell, TableHead, TableRow } from "@kiwicom/orbit-components";
import React from "react";

const OrderTableHeader = ({ items }) => {
  return (
    <TableHead>
      <TableRow>
        {items.map((item, i) => (
          <TableCell key={i} >{item}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderTableHeader;
