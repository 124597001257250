export const categories = [
  {
    label: "Pet Food",
    value: "pet_food",
  },
  {
    label: "Pet Products",
    value: "pet_products",
  },
  {
    label: "Pet Shampoo",
    value: "pet_shampoo",
  },
  {
    label: "Pet Skincare",
    value: "pet_skincare",
  },
];

export const labels = [
  {
    label: "Veg",
    value: "veg",
  },
  {
    label: "Non-Veg",
    value: "nonveg",
  },
];

export const weightVariants = [
  {
    label: "Gms",
    value: "Grams",
  },
  {
    label: "ml",
    value: "MilliLiter",
  },
  {
    label: "Ltr",
    value: "Liter",
  },
  {
    label: "Kgs",
    value: "Kilogram",
  },
];
