import { Heading, LinkList, Stack } from "@kiwicom/orbit-components";
import React from "react";
import { Outlet } from "react-router";
import LinkTile from "../components/LinkTile";

const ManageOrdersPage = () => {
  return (
    <Stack>
      <Heading>Manage Orders</Heading>
      <LinkList direction="row">
        <LinkTile to="ship" title="View Ship Orders" />
        <LinkTile to="update-shipment" title="Add Tracking Details" />
        <LinkTile to="customers" title="By Customer" />
      </LinkList>
      <Outlet />
    </Stack>
  );
};

export default ManageOrdersPage;
