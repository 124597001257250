export const items = [
  "Check",
  "Customer",
  "Ordered on",
  "Shop Products",
  "Address",
  "Total Bill",
  "Payment details",
];

export const getSelectedOrders = (checked) => {
  let shipmentOrders = [];

  Object.keys(checked).forEach((key) => {
    if (checked[key]) shipmentOrders.push(key);
  });

  return shipmentOrders;
};
