/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { fetchPharmacies } from "../../services/pharmacy.service";
import {
  Stack,
  Grid,
  Loading,
  Button,
  Box,
  Heading,
  Text,
} from "@kiwicom/orbit-components";

import { Reload } from "@kiwicom/orbit-components/icons";
import PharmacyItem from "../../components/PharmacyItem";

const PharmaciesGrid = () => {
  const [pharmacies, setPharmacies] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);

  const populateDocs = (docs) => {
    if (docs === undefined) return setLoading(false);
    setLastDoc(docs[docs.length - 1]);

    let data = [];

    docs.forEach((doc) => {
      data.push({ ...doc.data(), ref: doc.ref });
    });

    setPharmacies((prevData) => [...prevData, ...data]);
    setLoading(false);
  };

  const removePharmacyFromList = (pharmacy) => {
    setLoading(true);
    const ind = pharmacies.indexOf(pharmacy);
    console.log(`index of the pharmacy is: ${ind}`);
    if (ind > -1) {
      setPharmacies(pharmacies.filter((item) => item !== pharmacy));
      console.log("pharmacy removed from the list");
    }
    setLoading(false);
  };

  const getPharmaciesOnInit = () => {
    setLoading(true);
    fetchPharmacies(lastDoc).then(
      (result) => {
        populateDocs(result);
      },
      (_) => setLoading(false)
    );
  };

  useEffect(() => getPharmaciesOnInit(), []);

  return !loading ? (
    <Stack direction="column">
      <Heading>Approve Pharmacies</Heading>
      <Grid
        largeDesktop={{ columns: "repeat(3, 1fr)" }}
        mediumMobile={{ columns: "repeat(2, 1fr)" }}
        rowGap="1.5rem"
        columnGap="1rem"
      >
        {pharmacies.length === 0 && <Text align="center">No Pharmacies </Text>}
        {pharmacies.length !== 0 &&
          pharmacies.map((eachPharmacy) => (
            <PharmacyItem
              key={eachPharmacy.id}
              pharmacy={eachPharmacy}
              removePharmacyFromList={() =>
                removePharmacyFromList(eachPharmacy)
              }
            />
          ))}
      </Grid>
      {pharmacies.length > 3 && (
        <Box padding={{ bottom: "XXLarge" }}>
          <Button
            iconLeft={<Reload />}
            type="secondary"
            onClick={() => getPharmaciesOnInit()}
            spaceAfter="largest"
          >
            Load More
          </Button>
        </Box>
      )}
    </Stack>
  ) : (
    <Loading type="pageLoader" />
  );
};

export default PharmaciesGrid;
