/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState } from "react";
import {
  Loading,
  Table,
  TableBody,
  Heading,
  Button,
  Stack,
  Box,
  Text,
  Illustration
} from "@kiwicom/orbit-components";
import { Reload } from "@kiwicom/orbit-components/lib/icons";

import OrderHeader from "../../components/OrderHeader";
import OrderFooter from "../../components/OrderFooter";
import { fetchOrders } from "../../services/order.service";
import OrderItem from "./OrderItem";
import { items } from "./data";

const Ship = () => {
  const [loading, setLoading] = useState(true);
  const [orders, fetchShopOrders] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);

  const populateDocs = (docs) => {
    if (docs === undefined) return setLoading(false);
    setLastDoc(docs[docs.length - 1]);

    let data = [];

    docs.forEach((doc) => {
      data.push({ ...doc.data(), ref: doc.ref });
    });

    fetchShopOrders((prevData) => [...prevData, ...data]);
    setLoading(false);
  };

  const getOrders = () => {
    setLoading(true);
    fetchOrders(lastDoc, "shipped").then(
      (result) => {
        populateDocs(result);
      },
      (_) => setLoading(false)
    );
  };

  const removeItem = (value) => {
    fetchShopOrders((prevState) => prevState.filter((item) => item !== value));
  };

  useEffect(() => getOrders(), []);

  return !loading ? (
    <Box elevation="action" borderRadius="large" padding="large">
      <Stack direction="column" justify="between" align="start">
        <Heading type="title2" spaceAfter="largest">
          Update Shipment details
        </Heading>

        {orders.length > 0 ? (
          <Table striped={false}>
            <OrderHeader items={items} />
            <TableBody>
              {orders.map((order, i) => (
                <OrderItem
                  onDone={() => removeItem(order)}
                  key={i}
                  order={order}
                />
              ))}
            </TableBody>
            <OrderFooter
              length={5}
              suffix={
                <Button
                  size="small"
                  iconLeft={<Reload />}
                  type="secondary"
                  onClick={() => getOrders()}
                >
                  Load more
                </Button>
              }
            />
          </Table>
        ) : (
          <Stack direction="column" justify="center" align="center" >
            <Illustration spaceAfter="large" name="NoResults" />
            <Text size="large" >No orders right now</Text>
          </Stack>
        )}
      </Stack>
    </Box>
  ) : (
    <Loading type="pageLoader" />
  );
};

export default Ship;
