import { initializeApp } from "@firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD7vLCGFsTgGjWHiH_F3vZHI8_S_3qMyyg",
  authDomain: "vetremedihq.firebaseapp.com",
  projectId: "vetremedihq",
  storageBucket: "vetremedihq.appspot.com",
  messagingSenderId: "1031070226417",
  appId: "1:1031070226417:web:f6adab5d5ca3dea3e11650",
  measurementId: "G-B3Z0SGNEHB",
};

const app = initializeApp(firebaseConfig);

export default app