import React from "react";
import { Heading, Illustration, Stack, Text } from "@kiwicom/orbit-components";

const NotFound = () => {
  return (
    <Stack direction='column' justify="center" align="center">
      <Illustration name="Error404" />
      <Heading>Page not found!</Heading>
      <Text size="large" type="secondary" >Seems like you might lost or the dev's still cooking this page! who knows??</Text>
    </Stack>
  );
};

export default NotFound;
