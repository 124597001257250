import React from "react";
import { WizardStep, Wizard, Box } from "@kiwicom/orbit-components";

import NewProductView from "../views/NewProduct";
import AddVariantsView from "../views/AddVariants";
import ReviewProductView from "../views/ReviewProduct";

const AddNewProductPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const wizardSteps = ["Product details", "Add Variants", "Review and Rollout"];

  return (
    <>
      <Wizard
        id="wizard"
        completedSteps={activeStep}
        activeStep={activeStep}
        onChangeStep={(step) => handleStepChange(step)}
      >
        {wizardSteps.map((step) => (
          <WizardStep key={step} title={step} />
        ))}
      </Wizard>
      <Box
        padding={{
          top: "XLarge",
        }}
      >
        {activeStep === 0 ? (
          <NewProductView
            title={wizardSteps[activeStep]}
            onDone={() => {
              setActiveStep(1);
            }}
          />
        ) : activeStep === 1 ? (
          <AddVariantsView
            title={wizardSteps[activeStep]}
            onDone={() => {
              setActiveStep(2);
            }}
          />
        ) : (
          <ReviewProductView title={wizardSteps[activeStep]}
            onEditVariants={() => {
              setActiveStep(1);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default AddNewProductPage;
