import { createStore } from "easy-peasy";
import actions from "./actions";

const store = createStore({
  products: [],
  shopOrders: [],
  imageFiles: [],
  product: {},
  variants: [],
  ...actions,
});

export default store